import api from "../api/api";

const setupLocalStorage = (payload) =>{
    /*let user = {
        first_name : payload.first_name,
        last_name : payload.last_name,
        email:payload.email,
        id:payload.id,
        phone:payload.phone,
        token_id: payload.token_id,
        token_key: payload.token_key
    }
    */
    localStorage.setItem('authUser',JSON.stringify(payload));
}

const attemptLogin = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("auth/login",payload).then( (res)=>{
            console.log(res);
            if(!res.data.error){
                setupLocalStorage(res.data.data)
                resolve(res);
            } else {
                reject({
                    message: res.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}



const doSignup = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("auth/register",payload).then( (res)=>{
            console.log(res);
            if(!res.data.error){
                setupLocalStorage(res.data.data)
                resolve(res);
            } else {
                reject({
                    message: res.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getProfileDetails = () =>{
    return new Promise((resolve, reject)=>{
        api.get("profile/details").then( (res)=>{
            if(!res.data.error){
                resolve(res.data.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateProfileData = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("profile/update-data",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const changePassword = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/auth/change-password",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}

const authServices = {
    attemptLogin,
    doSignup,
    updateProfileData,
    getProfileDetails,
    changePassword,
}

export default authServices;
