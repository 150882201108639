import React, { useCallback, useEffect, useState } from 'react'
import AppLayout from '../../../layout/components/AppLayout'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Animation } from '@devexpress/dx-react-chart';

import Paper from '@mui/material/Paper';
import { ArgumentScale, Stack } from '@devexpress/dx-react-chart';
import {
  Chart,
  BarSeries,
  LineSeries,
  ArgumentAxis,
  ValueAxis,
  Legend,

  
  
  
  
} from '@devexpress/dx-react-chart-material-ui';
import { scaleBand } from '@devexpress/dx-chart-core';



import reportServices from '../reportServices';


const data = [
    { year: '1950', population: 2.525 },
    { year: '1960', population: 3.018 },
    { year: '1970', population: 3.682 },
    { year: '1980', population: 4.440 },
    { year: '1990', population: 5.310 },
    { year: '2000', population: 6.127 },
    { year: '2010', population: 6.930 },
  ];

  const ageStructure = [{
    state: 'Germany',
    young: 6.7,
    middle: 28.6,
    older: 5.1,
  }, {
    state: 'Japan',
    young: 9.6,
    middle: 43.4,
    older: 9,
  }, {
    state: 'Russia',
    young: 13.5,
    middle: 49,
    older: 5.8,
  }, {
    state: 'USA',
    young: 30,
    middle: 90.3,
    older: 14.5,
  }];


  const ageUpdated = [{
    state: 'Germany',
    young: 10,
    middle: 25,
    older: 8,
  }, {
    state: 'Japan',
    young: 6,
    middle: 55,
    older: 16,
  }, {
    state: 'Russia',
    young: 21,
    middle: 78,
    older: 3,
  }, {
    state: 'USA',
    young: 40,
    middle: 10,
    older: 55.5,
  }];

export default function GraphRef() {

    const [deviceId, setDeviceId] = useState('');

    const [startDate, setStartDate] = useState(dayjs('2021-12-01'));
    const [endDate, setEndDate] = useState(dayjs('2021-12-24'));

    const [ageList,setAgeList] = useState(ageStructure);



    const getData = useCallback(()=>{
        
        let q = `?deviceId=${deviceId}&startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format("YYYY-MM-DD")}`;
        reportServices.getReport(q).then(res=>{
            console.log(res)
        })

    },[deviceId,startDate,endDate])


    useEffect(()=>{
        getData();
    },[getData]);


    
    console.log("I am rerendered");



    /* Chart Work Start */

    const generateData = (start, end, step) => {
        const data = [];
        for (let i = start; i < end; i += step) {
          data.push({ splineValue: Math.sin(i) / i, lineValue: ((i / 15) ** 2.718) - 0.2, argument: i });
        }
      
        return data;
      };
 
    /* Chart Work End */


    return (
      <AppLayout>
          <div className="container">

            <div className="row ">
                <div className="col-md-6 mt-2">
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="demo-simple-select-label">Select Device</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={deviceId}
                            label="Device"
                            onChange={(e)=>setDeviceId(e.target.value)}
                        >
                        <MenuItem value={10}>STWFTL0521001</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="col-md-3 mt-2">
                        <MobileDatePicker
                            label="From"
                            inputFormat="MM/DD/YYYY"
                            value={startDate}
                            onChange={(newValue)=>setStartDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                    <div className="col-md-3 mt-2">
                        <MobileDatePicker
                            label="To"
                            inputFormat="MM/DD/YYYY"
                            value={endDate}
                            onChange={(newValue)=>setEndDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                        
                        
                        
                    
                </LocalizationProvider>
                    
                
            </div>

            
            <div className="row">
                <div className="col-md-6">
                    <Paper>
                        <Chart
                            data={data}
                        >
                            <Animation />
                            <ArgumentAxis />
                            <ValueAxis />
                            <Legend />
                            <BarSeries
                                valueField="population"
                                argumentField="year"
                                name="Population"
                            />
                        </Chart>
                    </Paper>
                </div>

                <div className="col-dm-12">
                        <button type="button" className="btn btn-primary" onClick={()=>setAgeList(ageUpdated)}>UPdate Data</button>
                </div>
                <div className="col-md-6">
                

                <Paper>
                    <Chart
                    data={ageList}
                    >
                        <ArgumentAxis />
                        <ValueAxis />
                        <Legend />
                        <Animation />
                        
                        <BarSeries
                            valueField="young"
                            argumentField="state"
                            name="Young"
                        />
                        <BarSeries
                            valueField="middle"
                            argumentField="state"
                            name="Middle"
                        />
                        <BarSeries
                            valueField="older"
                            argumentField="state"
                            name="Older"
                        />
                        <Stack />
                    </Chart>
                </Paper>

                </div>

                <div className="col-md-6">
                    <Paper>
                        <Chart
                        data={ageList}
                        >
                            <ArgumentAxis />
                            <ValueAxis />
                            <Legend />
                            <Animation />
                            <LineSeries
                                valueField="young"
                                argumentField="state"
                                name="Young"
                            />
                            <LineSeries
                                valueField="middle"
                                argumentField="state"
                                name="Middle"
                            />
                            <LineSeries
                                valueField="older"
                                argumentField="state"
                                name="Older"
                            />
                            <Stack />
                        </Chart>
                    </Paper>
                </div>

            </div>
          </div>
      </AppLayout>
    )
}
