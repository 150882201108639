import api from "../api/api"




const getData = (masters) => {
    return new Promise((resolve, reject)=>{
        api.get('device-masters/data/'+masters).then((res) => {
            resolve(res.data);
        }).catch(error =>{
            reject({
                message: "Something went wrong while loading masters",
                error : error
            })
        })
    })
}


const get = (masters) => {
    return new Promise((resolve, reject)=>{
        api.get('device-masters/dd/'+masters.join()).then((res) => {
            //console.log(res.data);
            let allMasters = res.data;
            let data = {};
            for (const master in allMasters) {
                if(!allMasters[master].error){
                    data[master] = allMasters[master].data;
                }
            }
            resolve(data);
        }).catch(error =>{
            reject("Something went wrong while loading masters");
        })
    })
}



const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        let data = {...payload};
        delete data.masterName;
        //console.log("here ");
        
        api.post("device-masters/data/"+payload.masterName,data).then( (res)=>{
            console.log(res.data)
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
        
    })
}


const remove = (payload) => {
    return new Promise((resolve, reject)=>{
        api.delete("device-masters/data/"+payload.masterName+"/"+payload.id).then( (res)=>{
            if(!res.data.error){
                resolve(res);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const deviceMasterServices = {
    getData,
    get,
    save,
    remove,
}

export default deviceMasterServices;