import React from 'react'

export default function LabelValue({label,value}) {
  return (
    <>
        {label &&
            <div className="row">
                <div className="col-md-6">
                    <strong>{label} : </strong>
                </div>
            <div className={"col-md-6"}>
                {<span className="text-dark">  {value}  </span> }
            </div>
        </div>
        }
        {!label && <span className="text-dark">  {value}  </span> }
    </>
  )
}
