import React, { useState } from 'react'
import { useParams } from 'react-router'
import AppLayout from '../../../layout/components/AppLayout'
import BasicDetailsComponent from '../components/BasicDetails/BasicDetailsComponent';
import DeviceContactDetailsComponent from '../components/DeviceContactDetails/DeviceContactDetailsComponent';
import DeviceLayout from '../components/DeviceLayout'
import DeviceSyncDetailsComponent from '../components/DeviceSyncDetails/DeviceSyncDetailsComponent';
import SensorConfigComponent from '../components/SensorConfig/SensorConfigComponent';


import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { CloudSync, ContactMail, Dashboard, DataObject, DevicesOther, PeopleAlt, SettingsInputComponent } from '@mui/icons-material';
import DeviceUserDetailsComponent from '../components/DeviceUserDetails/DeviceUserDetailsComponent';
import DeviceApiConfig from '../components/DeviceApiConfig/DeviceApiConfig';
import DeviceDashboardConfig from '../components/DeviceDashboardConfig/DeviceDashboardConfig';

const DeviceDetails = ({AppAlert}) =>{
    let { deviceId } = useParams();

    let hash = window.location.hash.substring(1);

    const [value, setValue] = useState('basic-details');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
      <AppLayout>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="bg-dark text-white">
                {/* variant="scrollable" scrollButtons="auto" */}
                <TabList onChange={handleChange} textColor="inherit" indicatorColor="secondary"  centered={true}>
                    <Tab icon={<DevicesOther />} label="Basic Details" value="basic-details" />
                    <Tab icon={<CloudSync />} label="Device Sync Details" value="device-sync-details" />
                    <Tab icon={<ContactMail />}label="Communications Details" value="communication" />
                    <Tab icon={<SettingsInputComponent />} label="Sensor Configuration" value="sensor-config" />
                    <Tab icon={<PeopleAlt />} label="Device User" value="device-user" />
                    <Tab icon={<DataObject />} label="API Config" value="device-api-config" />
                    <Tab icon={<Dashboard />} label="Data Config" value="device-dashboard-config" />
                </TabList>
                </Box>
                <div className="container">
                    <TabPanel value="basic-details"><BasicDetailsComponent deviceId={deviceId} /></TabPanel>
                    <TabPanel value="device-sync-details"><DeviceSyncDetailsComponent deviceId={deviceId} /></TabPanel>
                    <TabPanel value="communication"><DeviceContactDetailsComponent deviceId={deviceId} /></TabPanel>
                    <TabPanel value="sensor-config"><SensorConfigComponent deviceId={deviceId} /></TabPanel>
                    <TabPanel value="device-user"><DeviceUserDetailsComponent deviceId={deviceId} /></TabPanel>
                    <TabPanel value="device-api-config"><DeviceApiConfig deviceId={deviceId} /></TabPanel>
                    <TabPanel value="device-dashboard-config"><DeviceDashboardConfig deviceId={deviceId} /></TabPanel>
                </div>
            </TabContext>
        


          {/*
          <DeviceLayout deviceId={deviceId}>
            
            
            
            
            
          </DeviceLayout>
          */}
      </AppLayout>
    )
}

export default DeviceDetails;