import { CastConnected, ContentCopy } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'

import CommonLoadingSkeleton from '../../../../components/CommonLoadingSkeleton';
import LabelValue from '../../../../components/InlineEdit/LabelValue'
import withAlert from '../../../../hoc/withAlert'
import deviceServices from '../../deviceServices';
import BasicDetailsForm from './BasicDetailsForm';
import ImageInline from '../../../../components/InlineEdit/ImageInline';
const  BasicDetailsComponent = ({deviceId, AppAlert}) =>{

    

    let [deviceDetails,setDeviceDetails] = useState(null);
    let [loading,setLoadingStatus] = useState(false);


    const getDeviceDetails = useCallback(()=>{
        setLoadingStatus(true);
        deviceServices.get(deviceId).then(res=>{
            setDeviceDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    },[deviceId]);

    useEffect(()=>{
        getDeviceDetails();
    },[])



    return (
        <>
            {loading && <CommonLoadingSkeleton /> }
            
            {deviceDetails && 
                <div className="row">
                    <div className="col-md-3">
                        <ImageInline label="Device Image" name="deviceImage" value={deviceDetails.deviceImage} editId={deviceDetails.id} updateInline={deviceServices.updateInline} AppAlert={AppAlert} />
                    </div>
                    <div className="col-md-9">
                            <div className="card mb-2">
                                <div className="card-header bg-primary text-white">
                                    <span className="float-start"> <CastConnected/> <strong> {deviceDetails.Device_ID} </strong></span>
                                    <span className="float-end"><BasicDetailsForm refreshList={getDeviceDetails} AppAlert={AppAlert} data={deviceDetails}/></span>
                                </div>
                                <div className="card-body" id="device-details">
                                    <div className="row">
                                        <div className="col-md-6"> 
                                            <LabelValue label={"Device Id"} value={deviceDetails.Device_ID} />
                                            <LabelValue label={"Api Key"} value={deviceDetails.api_key} />
                                            <LabelValue label={"Device Name"} value={deviceDetails.deviceName ? deviceDetails.deviceName:"N.A" } />
                                            <LabelValue label={"Device Location"} value={deviceDetails.location?deviceDetails.location:"N.A"} />

                                            <LabelValue label={"Cam Available"} value={deviceDetails.camStatus?"Yes":"No"} />
                                            {deviceDetails.camStatus != 0 && <LabelValue label={"Cam Link"} value={deviceDetails.camLink?deviceDetails.camLink:"N.A"} />}
                                            
                                        </div>
                                        <div className="col-md-6">
                                            <LabelValue label={"User Device Id"} value={deviceDetails.User_Device_ID} />
                                            <LabelValue label={"Display Name"} value={deviceDetails.displayTitle ? deviceDetails.displayTitle :"N.A"} />
                                            <LabelValue label={"Install Date"} value={deviceDetails.created_at} />
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                

                                <div className="row">
                                    <div className="offset-2 col-md-8 mt-3">
                                        
                                        
                                        <span className="float-start"><label htmlFor="api-url" className="form-label"><strong>Device API URL</strong></label></span>
                                        <span className="float-end">
                                            
                                            
                                            <Tooltip title="copy to clipboard" arrow>
                                                <Button type="button" variant="outlined" className="mb-3"
                                                    onClick={() => {navigator.clipboard.writeText(process.env.REACT_APP_BASE_URL+"network/device/"+deviceDetails.Device_ID)}}
                                                >
                                                <ContentCopy/> copy
                                                </Button>
                                            </Tooltip>
                                        </span>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" >{process.env.REACT_APP_BASE_URL}network/device/</span>
                                            <input type="text" className="form-control" onChange={()=>{}} value={deviceDetails.Device_ID}/>
                                        </div>
                                        
                                        
                                        
                                    </div>
                                </div>
                            </div>
                    </div>
                        
                </div>
            }
            
            

        </>
    )
}


export default withAlert(BasicDetailsComponent)