import api from "../../api/api";

const getCamDetails = (deviceId) =>{
    return new Promise((resolve, reject)=>{
        api.get("report/cam-details/"+deviceId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const mqttOptions = {
    // Clean session
    clean: true,
    protocol: "mqtts",//process.env.REACT_APP_MQ_PROTOCOL,
    port: 443,//process.env.REACT_APP_MQ_PORT,
    connectTimeout: 4000,
    // Authentication
    clientId: Math.floor(Math.random() * 100),
    username: process.env.REACT_APP_MQ_USERNAME,
    password: process.env.REACT_APP_MQ_PASSWORD,
}


const mqURL = process.env.REACT_APP_MQ_URL;

const mqServices = {
    mqttOptions,
    mqURL,
    getCamDetails
}
export default mqServices;