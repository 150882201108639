import api from "../../api/api";


const get = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/user-device").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getById = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/user-device/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const userDeviceServices = {
    get,
    getById
}
export default userDeviceServices;