import api from "../../../../api/api";


const setupDevice = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/device-setup/data-table",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg,
                    id: res.data.id?res.data.id:false,
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const installDeviceServices = {
    setupDevice,
}
export default installDeviceServices;