import React, { useState } from 'react'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';




import {
    Chart,
    BarSeries,
    LineSeries,
    ArgumentAxis,
    ValueAxis,
    Legend,
    Title,
  } from '@devexpress/dx-react-chart-material-ui';

import { Animation } from '@devexpress/dx-react-chart';
import Paper from '@mui/material/Paper';
import { ArgumentScale, Stack } from '@devexpress/dx-react-chart';

/*
import { scaleBand } from '@devexpress/dx-chart-core';
import { ArgumentScale, Stack } from '@devexpress/dx-react-chart';
*/

  

export default function STWFMBA0122001ReportComponent({data}) {
    
    
    
    const [columnDefs] = useState([
        { width:80, sortable: true,filter:true, field:"id", },
        { width:150, sortable: true,filter:true, field:"TimeDate", },
        { width:80, sortable: true,filter:true, field:"CH1", },
        { width:80, sortable: true,filter:true, field:"CH2", },
        { width:80, sortable: true,filter:true, field:"CH3", },
        { width:80, sortable: true,filter:true, field:"CH4", },
        { width:80, sortable: true,filter:true, field:"CH5", },
        { width:80, sortable: true,filter:true, field:"CH6", },
        { width:80, sortable: true,filter:true, field:"CH7", },
        { width:80, sortable: true,filter:true, field:"CH8", },
        
        
    ])

  return (
            <div className="row p-2">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-12">
                            <Paper>
                                <Chart
                                    data={data} height={250}
                                >
                                    <ArgumentAxis />
                                    <ValueAxis />
                                    <Legend />
                                    <Animation />
                                    <LineSeries
                                        valueField="CH1"
                                        argumentField="TimeDate"
                                        name="CH1"
                                    />

                                    <LineSeries
                                        valueField="CH2"
                                        argumentField="TimeDate"
                                        name="CH2"
                                    />

                                    <LineSeries
                                        valueField="CH3"
                                        argumentField="TimeDate"
                                        name="CH3"
                                    />
                                    <Title text="CH1,CH2,CH3" />

                                    <Stack />
                                </Chart>
                            </Paper>
                        </div>
                        <div className="col-md-12">
                            <Paper>
                                <Chart
                                data={data} height={250}
                                >
                                    <ArgumentAxis />
                                    <ValueAxis />
                                    <Legend />
                                    <Animation />
                                    <LineSeries
                                        valueField="CH4"
                                        argumentField="TimeDate"
                                        name="CH4"
                                    />
                                    <LineSeries
                                        valueField="CH5"
                                        argumentField="TimeDate"
                                        name="CH5"
                                    />
                                    <LineSeries
                                        valueField="CH6"
                                        argumentField="TimeDate"
                                        name="CH6"
                                    />

                                    <Title text="CH4,CH5,CH6" />
                                    <Stack />
                                </Chart>
                            </Paper>
                        </div>


                    </div>
                </div>


                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-12">
                        {data && 
                            <>
                                <h3>Data Log</h3>
                                <div className="ag-theme-alpine" style={{height: 400, width:"auto"}}>
                                    <AgGridReact
                                        rowData={data}
                                        columnDefs={columnDefs}>
                                    </AgGridReact>
                                </div>
                            </>
                        }
                        </div>
                    </div>
                </div>


                
            </div>
  )
}
