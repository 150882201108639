import React,{useEffect,useState} from 'react'
import mqtt from "precompiled-mqtt";
import mqServices from './mqServices';
import { Link } from 'react-router-dom';





function DeviceMQTT({deviceId,mqClient,camDetails}) {

    const [topic,setTopic] = useState(deviceId+"/");

    const [deviceState,setDeviceState]  = useState(null);

    useEffect(()=>{
        mqClient.on('connect', () => {
            console.log('Connected for '+deviceId);
            mqClient.subscribe(topic+"ack/cam", function (err) {
                if (!err) {
                    console.log("subscribed to topic : ", topic+"ack/cam")
                  // Publish a message to a topic
                    let message = "on"
                    mqClient.publish(topic+"command/cam/init","state");
                    //mqClient.publish(topic+'/command/cam/state',"off")
                } else {
                    console.log("error on subscribe",err)
                }
            })
        });
        return () =>{
            mqClient.unsubscribe(topic+"ack/cam", function (error) {
                if (error) {
                  console.log(error)
                } else {
                  console.log('Unsubscribed ',topic+'ack')
                }
            })
        }
    },[mqClient])

    const publishMessage = (message) =>{
        console.log("publishing message");
        //mqClient.publish(topic+"command",'{"Site_ID":"Area1","Device_ID":"STPAQTT11230001","T":"1205.mp3","T_PT":0,"H_PT":0,"TS":true,"HS":false}');
        console.log("device Message","off")
        mqClient.publish(topic+"command/cam/state",message);
        console.log("message published");
    }

    const publishTestMessage = () =>{
        console.log("publishing message");
        mqClient.publish(topic+"command",'{"Site_ID":"Area1","Device_ID":"STPAQTT11230001","T":"1205.mp3","T_PT":0,"H_PT":0,"TS":true,"HS":false}');
        console.log("message published");
    }

    mqClient.on('message', function (topic, message) {
        // message is Buffer
        console.log("message received for "+topic);
        let m = message.toString();
        //let messageObj = JSON.parse(m);
        console.log(m)

        //if(messageObj.Device_ID === deviceDetails.deviceName){
            setDeviceState(m);
        //}
        
        //client.end()
    })



    return (
        <>
            {deviceState !== null &&
                <>
                    <button className={deviceState === "CAM ON" ? "btn btn-success mx-3" : "btn btn-danger mx-3"}
                        onClick ={ ()=>{
                                if(deviceState === "CAM ON"){
                                    publishMessage("off")
                                } else {
                                    publishMessage("on")
                                }
                            }
                        }
                    >{deviceState}
                    </button> 
                    { deviceState === "CAM ON" && camDetails.camLink !== null && camDetails.camLink !== "" &&
                        <a href={camDetails.camLink} className="btn btn-link" target="_blank">View Camera</a>
                    }
                </>
            }
        </>

    )
}





function MQcomponent({deviceId}) {

    const [mqClient,setMQClient] = useState(null);
    const [camDetails,setCamDetails] = useState(null);

    const getCamDetails = () =>{
        mqServices.getCamDetails(deviceId).then(res=>{
            setCamDetails(res.data);
        }).catch(e=>{
            console.log("something went wrong");
        })
    }

    const connectMQTT = () =>{
        if(camDetails?.camStatus == 1){
            //console.log("device list ready now get mqttOptions",deviceClientServices.mqttOptions)
            //console.log("device list ready now get url",deviceClientServices.mqURL)
            let client  = mqtt.connect(mqServices.mqURL, mqServices.mqttOptions);
            setMQClient(client);
            console.log("mqClientReady");
        }
    }

    useEffect(()=>{
        connectMQTT();
    },[camDetails])

    useEffect(()=>{
        getCamDetails()
    },[deviceId])

    return (
        <>
            { camDetails && mqClient && 
                <DeviceMQTT mqClient={mqClient} camDetails={camDetails} deviceId={deviceId} />
            }
        </>
    )
}

export default MQcomponent