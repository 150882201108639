import { Add, CheckBox, CheckBoxOutlineBlank, CheckCircle, Close, Delete } from '@mui/icons-material';
import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import withAlert from '../../../../hoc/withAlert';
import apiConfigServices from './apiConfigServices';


function DeviceApiConfig({deviceId,AppAlert}) {

    
    const [apiConfig,setApiConfig] = useState(null);
    const [deviceData,setDeviceData] = useState(null);
    const [sensorData,setSensorData] = useState(null);

    const getApiConfig = () =>{
        apiConfigServices.getApiConfig(deviceId).then(res=>{
            setApiConfig(res.data);
            setDeviceData(res.data.deviceData);
            setSensorData(res.data.sensorData);
        }).catch(error=>{
            console.log(error)
        })
    }

    useEffect(()=>{
        getApiConfig()
    },[deviceId])

    const [modified,setModified] =  useState(false);

    const updateDevice = (i,key,value) => {
        setModified(true);
        let _deviceData = [...deviceData];
        _deviceData[i][key] = value;
        setDeviceData(_deviceData)
    }

    const removeDeviceData = (i) =>{
        setModified(true);
        let _deviceData = [...deviceData];
        _deviceData.splice(i,1);
        setDeviceData(_deviceData)
    }


    const [newLabel,setNewLabel] = useState("");
    const [newValue,setNewValue] = useState("");
    const addDeviceData = () =>{
        setModified(true);
        let newData = {
            'label':newLabel,
            'value':newValue
        }
        let _deviceData = [...deviceData,newData];
        setDeviceData(_deviceData)
        setNewLabel("");
        setNewValue("");
    }

    const updateSensor = (i,key,value) => {
        setModified(true);
        let _sensorData = [...sensorData];
        _sensorData[i][key] = value;
        setSensorData(_sensorData)
    }

    const saveConfiguration = () =>{
        let payload  = {
            deviceId : deviceId,
            deviceData:deviceData,
            sensorData:sensorData
        }
        console.log(payload);
        apiConfigServices.updateApiConfig(payload).then(res=>{
            console.log(res);
            setModified(false);
            AppAlert("Updated Successfully")
        }).catch(error=>{
            console.log(error)
        })
    }


    

    return (
        apiConfig && sensorData && deviceData &&
        <div className="row">
            <div className="col-md-8">
                <div className="card">
                    <div className="card-header">Device Informaton</div>
                    <div className="card-body">
                        {deviceData.map((info,i)=>
                            <div key={i} className="row mb-2">
                                <div className="col-md-4">
                                    <input type="text" value={info.label} onChange={(e)=>updateDevice(i,"label",e.target.value)} className="form-control" />
                                </div>
                                <div className="col-md-2">As</div>
                                <div className="col-md-4">
                                    <input type="text" value={info.value} onChange={(e)=>updateDevice(i,"value",e.target.value)} className="form-control" />
                                </div>
                                <div className="col-md-2"><button className="btn text-danger" onClick={()=>removeDeviceData(i)} ><Delete/></button></div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-md-4">
                                <input type="text" value={newLabel} onChange={(e)=>setNewLabel(e.target.value)} className="form-control" />
                            </div>
                            <div className="col-md-2">As</div>
                            <div className="col-md-4">
                                <input type="text" value={newValue} onChange={(e)=>setNewValue(e.target.value)} className="form-control" />
                            </div>
                            <div className="col-md-2"><button className="btn btn-success btn-sm" onClick={addDeviceData} ><Add/></button></div>
                            
                            <div className="col-md-12">{JSON.stringify(deviceData)}</div>

                        </div>
                    </div>
                    {/*
                    <div className="card-body">
                        {JSON.stringify(deviceData)}
                    </div>
                    */}
                </div>
            
                <div className="card">
                    <div className="card-header bg-primary text-white">Sensor Informaton</div>
                    <div className="card-body">
                        {sensorData.map((info,i)=>
                            <div key={i} className="row mb-2 bg-light">
                                <div className="col-md-4">{i+1}{info.channel}</div>
                                <div className="col-md-1">As</div>
                                <div className="col-md-5">
                                    <input type="text" value={info.label} onChange={(e)=>updateSensor(i,"label",e.target.value)} className="form-control" />
                                </div>
                                <div className="col-md-2">
                                    { info.status === 1 ?
                                        <button className="btn text-success" onClick={()=>updateSensor(i,"status",0)} ><CheckBox/></button>
                                        :
                                        <button className="btn text-danger" onClick={()=>updateSensor(i,"status",1)}><CheckBoxOutlineBlank/></button>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                    {/*
                    <div className="card-body">
                        {JSON.stringify(sensorData)}
                    </div>
                    */}
                </div>
            </div>
            <div className="col-md-4">
                <div className="card">
                    <div className="card-header">API Details </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 mb-2"><span className="fw-bold">Device : </span><input type="text" className="form-control" value={apiConfig.Device_ID} /></div>
                            <div className="col-md-12 mb-2"><span className="fw-bold">Token : </span><input type="text" className="form-control" value={apiConfig.token} /></div>
                            <div className="col-md-12 mb-2">
                                <span className="fw-bold">Api Get Url : </span>
                                <div className="text-primary bg-light">
                                    {process.env.REACT_APP_BASE_URL}network/data/{apiConfig.Device_ID}?token={apiConfig.token}
                                </div>
                            </div>

                            <div className="col-md-12 mb-2">
                                <span className="fw-bold">Api Post Url : </span>
                                <div className="text-primary bg-light">
                                    {process.env.REACT_APP_BASE_URL}network/data/{apiConfig.Device_ID}
                                </div>
                            </div>
                            <div className="col-md-12">

                            </div>
                            <div className="col-md-12 mt-2">
                                {modified == true?
                                    <button className="btn ms-2 btn-primary float-end" onClick={saveConfiguration}  >Save Configuration</button>
                                    :
                                    <a target="_blank" className="btn ms-2 btn-primary float-end" href={process.env.REACT_APP_BASE_URL+"network/data/"+apiConfig.Device_ID+"?token="+apiConfig.token}>Verify</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withAlert(DeviceApiConfig)