import React,{useState,useEffect} from 'react'
import moment from 'moment'
import reportServices from './../reportServices'

function MinMaxReading({deviceId,startDate=moment().format("YYYY-MM-DD"),endDate=moment().format("YYYY-MM-DD")}) {

    const [reading,setReading] = useState(null);
    

    const getLastReading = () =>{
        reportServices.getMinMaxReading(deviceId,startDate,endDate).then(res=>{
            if(res.data.length > 0){
                setReading(res.data);
            } else {
                setReading(null)
            }
        })
    }

    useEffect(() => {
        getLastReading();
        /*
        const timer = setInterval(()=>{
            if (!document.hidden) {
                getLastReading();
            } 
        },60000)
        return ()=> clearInterval(timer)
        */
    },[] )



    const prepareVale = (value) =>{
        let v =  parseFloat(value)
        if(isNaN(v)){
            return value
        }
        //return v % 1 == 0?v:v.toFixed(3);
        return value
    }
    return (
        reading &&
        <div className="row mb-5">
            <div className="col-md-12 text-center mt-5 pt-4 bg-white ">
                <p className="text-success" style={{fontSize:24}}><strong>{ moment().format("dddd, LL") }</strong></p>
            </div>
            <div className="col-md-12 table-responsive bg-white">
                <table className="table table-bordered" style={{fontSize:"16px"}}>
                    <thead>
                        <tr>
                            {reading.map((r,i)=>
                                r.minMax == 1 && <th key={i} className="text-center bg-dark text-white" colSpan={2}>{r.sensorType}</th>
                            )}
                        </tr>
                        <tr>
                            {reading.map((r,i)=>
                                r.minMax  == 1&&
                                <React.Fragment key={i}>
                                    <th className="text-center table-info">Min</th>
                                    <th className="text-center table-warning">Max</th>
                                </React.Fragment>
                            )}
                        </tr>
                        <tr>
                            {reading.map((r,i)=>
                                r.minMax == 1 &&
                                <React.Fragment key={i}>
                                    <td className="text-center table-info">{r.minValue} {r.sensorUnit}</td>
                                    <td className="text-center table-warning">{r.maxValue} {r.sensorUnit}</td>
                                </React.Fragment>
                            )}
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    )
}

export default MinMaxReading