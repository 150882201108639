import React, { useCallback, useEffect, useState } from 'react'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import moment from 'moment';

const DaySelector = ({range, frequency, onFrequencyChange}) =>{

    const [startDate,setStartDate] = useState(moment().format("YYYY-MM-DD") )
    const next= () =>{
        var date = new Date(startDate);
        date.setDate(date.getDate()+1)
        setStartDate(moment(date).format("YYYY-MM-DD"))
    }
    const prev= () =>{
        var date = new Date(startDate);
        date.setDate(date.getDate()-1)
        setStartDate(moment(date).format("YYYY-MM-DD"))
    }
    useEffect(()=>{
        console.log(" I am implemented from Day Selector");
        range(startDate,startDate);
    },[startDate])

    return (
        <div className="row mt-2">
            <div className="col-2 p-2 text-center">
                <Tooltip title="next">
                    <IconButton onClick={prev} >
                    <ArrowBackIos/>
                    </IconButton>
                </Tooltip>
            </div>
            <div className="col-8 text-center border p-3">
                {moment(startDate).format('ddd, DD-MMMM-YYYY')}
            </div>
            <div className="col-2 p-2 text-center">
                <Tooltip title="next">
                    <IconButton onClick={next} >
                        <ArrowForwardIos/>
                    </IconButton>
                </Tooltip>
            </div>
            <div className="col-6 mt-2 text-center">
                <button type="button" className={frequency === "all"? "btn btn-dark" : "btn btn-secondary"} onClick={()=>onFrequencyChange("all")}>All</button>
            </div>
            <div className="col-6 mt-2 text-center">
                <button type="button" className={frequency === "auto"? "btn btn-dark" : "btn btn-secondary"} onClick={()=>onFrequencyChange("auto")} >Hourly</button>
            </div>
        </div>
    )
}

const WeekSelector = ({range}) =>{

    let d = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    const [startDate,setStartDate] = useState(moment(d).format("YYYY-MM-DD") )

    const next= () =>{
        var date = new Date(startDate);
        date.setDate(date.getDate()+7);
        setStartDate(moment(date).format("YYYY-MM-DD"))
    }
    const prev= () =>{
        var date = new Date(startDate);
        date.setDate(date.getDate()-7);
        setStartDate(moment(date).format("YYYY-MM-DD"))
    }
    useEffect(()=>{
        console.log(" I am implemented from week Selector");
        range(startDate,getEndDate());
    },[startDate])

    const getEndDate = () =>{ //
        var date = new Date(startDate);
        date.setDate(date.getDate()+7);
        return moment(date).format("YYYY-MM-DD");
    }

    return (
        <div className="row  mt-2">
            <div className="col-2 p-2 text-center">
                <Tooltip title="next">
                    <IconButton onClick={prev} >
                    <ArrowBackIos/>
                    </IconButton>
                </Tooltip>
            </div>
            <div className="col-8 text-center border p-3">
                {moment(startDate).format('ddd, DD-MMM')} to  {moment(getEndDate()).format('ddd, DD-MMM')}
            </div>
            <div className="col-2 p-2 text-center">
                <Tooltip title="next">
                    <IconButton onClick={next} >
                        <ArrowForwardIos/>
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    )
}

const MonthSelector = ({range}) =>{

    let d = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    const [startDate,setStartDate] = useState(moment(d).format("YYYY-MM-DD") )

    const next= () =>{
        var date = new Date(startDate);
        date.setDate(date.getDate()+30);
        setStartDate(moment(date).format("YYYY-MM-DD"))
    }
    const prev= () =>{
        var date = new Date(startDate);
        date.setDate(date.getDate()-30);
        setStartDate(moment(date).format("YYYY-MM-DD"))
    }
    useEffect(()=>{
        console.log(" I am implemented from week Selector");
        range(startDate,getEndDate());
    },[startDate])

    const getEndDate = () =>{ //
        var date = new Date(startDate);
        date.setDate(date.getDate()+30);
        return moment(date).format("YYYY-MM-DD");
    }

    return (
        <div className="row mt-2">
            <div className="col-2 p-2 text-center">
                <Tooltip title="next">
                    <IconButton onClick={prev} >
                    <ArrowBackIos/>
                    </IconButton>
                </Tooltip>
            </div>
            <div className="col-8 text-center border pt-3 pb-3">
                {moment(startDate).format('Do, MMM YY')} to  {moment(getEndDate()).format('Do, MMM YY')}
            </div>
            <div className="col-2 p-2 text-center">
                <Tooltip title="next">
                    <IconButton onClick={next} >
                        <ArrowForwardIos/>
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    )
}

const DainiRangeSelecter = ({onChange, frequency, onFrequencyChange}) => {

    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const range = (startDate,endDate)=> {
        onChange(startDate,endDate);
    }
    return (
        <>
            <div className="row bg-white">
                <div className="col-12">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        centered
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Day" />
                        <Tab label="Week" />
                        <Tab label="Month" />
                    </Tabs>
                </div>
            </div>
            <div className="row bg-white">
                {value === 0 && <DaySelector range={range} frequency={frequency} onFrequencyChange={onFrequencyChange}  />}
                {value === 1 && <WeekSelector range={range}/>}
                {value === 2 && <MonthSelector range={range}/>}
            </div>
        </>
    );
}

export default DainiRangeSelecter;