import React from 'react'
import { Route, Routes } from 'react-router'
import Login from '../auth/pages/Login'
import Logout from '../auth/pages/Logout'
import Register from '../auth/pages/Register'
import Profile from '../auth/pages/Profile'
import Dashboard from '../dashboard/pages/Dashboard'
import UserListPage from '../users/pages/UserListPage'
import UserRolePage from '../users/pages/UserRolePage'
import NotificationComponent from '../modules/Notification/NotificationComponent'
import SystemMasterListPage from '../master/pages/SystemMasterListPage'
import DeviceMasterListPage from '../master/pages/DeviceMasterListPage'

import BusinessListPage from '../modules/Business/BusinessListPage'
import DeviceList from '../app/Device/pages/DeviceList'
import ReportList from '../app/Report/pages/ReportList'
import GraphRef from '../app/Report/pages/GraphRef'
import DeviceDetails from '../app/Device/pages/DeviceDetails'
import DeviceReport from '../app/Report/pages/DeviceReport'
import UserDeviceList from '../app/UserDevice/pages/UserDeviceList'
import DeviceReportNew from '../app/Report/pages/DeviceReportNew'
import DeviceConfig from '../app/Device/pages/DeviceConfig'
import DeviceLog from '../app/Report/pages/DeviceLog'
import ChangePassword from '../auth/pages/ChangePassword'
import DeviceHealthList from '../app/UserDevice/pages/DeviceHealthList'





export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={ <Dashboard/> } />
      <Route path="/auth/login" element={ <Login/> } />
      <Route path="/auth/change-password" element={ <ChangePassword /> } />
      {/* <Route path="/auth/register" element={ <Register/> } /> */}
      <Route path="/auth/profile" element={ <Profile/> } />
      <Route path="/auth/logout" element={ <Logout/> } />
      
      <Route path="/notification/list" element={ <NotificationComponent /> } />
      <Route path="/system-master/list" element={ <SystemMasterListPage /> } />
      <Route path="/device-master/list" element={ <DeviceMasterListPage /> } />

      <Route path="/business/details" element={ <BusinessListPage /> } />

      <Route path="/user/list" element={ <UserListPage/> } />
      <Route path="/user/role" element={ <UserRolePage/> } />




      <Route path="/device/list" element={ <DeviceList /> } />
      <Route path="/device/details/:deviceId" element={ <DeviceDetails /> } />


      <Route path="/device/config/:deviceId" element={ <DeviceConfig/> } />



      <Route path="/device/alert-info/:deviceId" element={ <DeviceDetails /> } />
      <Route path="/device/notification/:deviceId" element={ <DeviceDetails /> } />


      <Route path="/my/device/list" element={ <UserDeviceList /> } />
      <Route path="/my/device/health" element={ <DeviceHealthList /> } />



      <Route path="/report/device/:deviceId" element={ <DeviceReport/> } />
      <Route path="/report/device-new/:deviceId" element={ <DeviceReportNew /> } />
      <Route path="/report/log/:deviceId" element={ <DeviceLog /> } />

      {/* Only for Testing */}
      <Route path="/report/list" element={ <ReportList /> } />
      <Route path="/report/graph-ref" element={ <GraphRef /> } />

    </Routes>
  )
}
