
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import DainiRangeSelecter from '../../../components/DainiRangeSelector/DainiRangeSelector';
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import reportServices from '../reportServices';

import CircularProgress from '@mui/material/CircularProgress';



import LastReading from '../components.js/LastReading';
import { ArrowDropDown, Close } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { Chip, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import GraphReport from '../components.js/GraphReport';
import DataReport from '../components.js/DataReport';
import MinMaxReading from '../components.js/MinMaxReading';




const DainiDropDown = ({options, onChange}) =>{

    const [dialogStatus,setDialogStatus] =  useState(false);

    const handleClose = () =>{
        setDialogStatus(false);
    }

    

    return (
        <div className="row bg-white">
            <div className="col-md-12">
                <div className="row ps-3 pe-3">
                    <div className="col-md-12 mt-2">
                        <span className="">Select Sensor</span>
                    </div>
                </div>
                <div className="row ps-3 pe-3">
                    <div className="col-md-12 p-2 rounded border" onClick={()=>setDialogStatus(true)} style={{cursor:"pointer"}}>
                        <span className="float-start">
                            {options.map((sensor)=>
                                sensor.status ? <span className="m-1" key={sensor.value}><Chip color="primary" label={sensor.label}/></span> :false
                            )}
                        </span>
                        <span className="float-end">
                            <ArrowDropDown/>
                        </span>
                    </div>
                </div>
            </div>
            
            
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="xs"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <span className="float-start"> Select Sensor</span>
                    <span className="float-end"><button type="button" className="btn btn-primary" onClick={handleClose} ><Close/> </button></span>
                </DialogTitle>
                <DialogContent>
                    <List>
                        {options.map((sensor,i)=>
                            <ListItem key={sensor.value} disablePadding>
                                <ListItemButton  onClick={()=>onChange(i)}>
                                    <ListItemIcon>
                                        <Checkbox
                                        edge="start"
                                        checked={sensor.status}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={sensor.label} />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-primary" onClick={handleClose} >Done</button>
                </DialogActions>
            </Dialog>
        </div>

        
    )
}



const DeviceReport = ({AppAlert}) => {

    const {deviceId} = useParams()
    const [formValues, setFormValues] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [selectedCount, setSelectedCount] = useState(1);
    const [endDate, setEndDate] = useState(null);
    const [frequency,setFrequency] = useState('all');
    const [chartData,setChartData] = useState(null);
    const [loading, setLoadingStatus] = useState(false);

    const initialValues = {
        sensor:"",
    }
    
    const handleRangeChange = (startDate,endDate)=> {
        //console.log("Yes Start Date ",startDate);
        //console.log("Yes End Date ",endDate);
        setStartDate(startDate);
        setEndDate(endDate);
    }

    /* Prepair Device Sensor DD*/
    const [masters,setMasters] = useState(null);
    const getDeviceSensorListDD = () => {
        reportServices.getDeviceSensorListDD(deviceId).then(res=>{
            
            let sensor = res.data.map($s=>{
                $s['status'] = false;
                return $s;
            })
            sensor[0].status = true;
            setMasters(sensor)
            //setFormValues({sensor:res.data[0].value});
            
        })
    }

    const [validUser,setValidUser] = useState(true);
    const getReport = () =>{
        if(selectedCount > 0 && startDate !== null && endDate !== null ){
            let q = "?"
            q = q+"deviceId="+deviceId;
            q = q+"&startDate="+startDate;
            q = q+"&endDate="+endDate;
            q = q+"&sensor="+1;
            q = q+"&frequency="+frequency;
            console.log(q);
            setLoadingStatus(true);
            setChartData(null);
            reportServices.getReport(q).then(res=>{
                //console.log(res.data);
                setChartData(res.data.data);
            }).catch(error=>{
                console.log(error);
                setValidUser(false);
                AppAlert(error.message,"error");
            }).finally(()=>{
                setLoadingStatus(false);
            })

        }
    }

    useEffect(()=>{
        getReport();
    },[startDate,frequency ])

    
    useEffect(()=>{
        getDeviceSensorListDD();
    },[]);


    const handleSendorChange = (i) =>{
        let count = selectedCount;
        if(masters[i].status === false){
            masters[i].status = true;
            count++;
        } else {
            masters[i].status = false;
            count--;
        }
        if(count > 0 && count < 7){
            console.log("count",count);
            setMasters(masters);
            setSelectedCount(count);
        } else {
            
        }
        
    }

    const handleFrequencyChange = (f) =>{
        //console.log("Changed");
        setFrequency(f);
    }
    

    return (
    <>
        <AppLayout background={"hello"}>
                <div className="container" >
                    <LastReading deviceId={deviceId} />
                    <MinMaxReading deviceId={deviceId} />
                    {validUser && <DainiRangeSelecter frequency={frequency} onFrequencyChange={handleFrequencyChange} onChange={handleRangeChange} /> }
                    
                        {masters && validUser && 
                            <DainiDropDown options={masters} onChange={handleSendorChange} />
                        }

                    {loading && <div className="text-center mt-5"> <CircularProgress color="secondary" />  </div>}
                    {masters && selectedCount > 0  && chartData &&
                        <>
                        <GraphReport channels={masters} chartData={chartData} selectedCount={selectedCount}  />
                        <DataReport channels={masters} chartData={chartData} selectedCount={selectedCount}  />
                        </>
                    }
                    {!validUser && 
                        <div className="row m-5">
                            <div className="col-12 text-center">
                                <h3>Something Went wrong here</h3>
                            </div>
                        </div>
                    }

                </div>
            
        </AppLayout>
    </>
  )
}

export default withAlert(DeviceReport)