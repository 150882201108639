import api from "../../../../api/api";


const getDashboardConfig = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("dashboard-data/config/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg,
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const updateDashboardConfig = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("dashboard-data/config",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg,
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}






const dashboardConfigServices = {
    getDashboardConfig,
    updateDashboardConfig
}
export default dashboardConfigServices;