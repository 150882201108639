import React, { useCallback, useEffect, useState } from 'react'
import CommonLoadingSkeleton from '../../../../components/CommonLoadingSkeleton';
import withAlert from '../../../../hoc/withAlert'
import userServices from '../../../../users/userServices'

import DeviceUserDetailsForm from './DeviceUserDetailsForm';
import deviceUserServices from './deviceUserServices';

import ConfirmDialog from '../../../../components/ConfirmDialog';
import deviceServices from '../../deviceServices';


const  DeviceUserDetailsComponent = ({deviceId, AppAlert}) =>{

    let [deviceUserList,setDeviceUserList] = useState(null);
    let [loading,setLoadingStatus] = useState(false);

    const getDeviceUserList = useCallback(()=>{
        setLoadingStatus(true);
        deviceUserServices.get(deviceId).then(res=>{
            if(res.data.length > 0 ){
                setDeviceUserList(res.data);
            } else {
                setDeviceUserList(null);
            }
            

        }).catch(error=>{
            //AppAlert("Something wend wrong","error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    },[deviceId]);



    /* Prepair User DD*/
    const [masters,setMasters] = useState(null);
    const getUserDD = () => {
        
        userServices.getUserDD().then(res=>{
            setMasters({"users":res.data})
            getDeviceUserList()
        })
    }

    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return (
            <>
                <p>{label.firstName} {label.lastName}</p>
                <p><strong>Location : </strong> {label.workLocation ? label.workLocation :"NA"}</p>
            </>
        )
    }


    const remove = (id)=> {
        console.log("remove ",id);
        
        deviceUserServices.remove(id).then(res=>{
            getDeviceUserList();
            
        }).catch(error =>{
            
        })
        
    }

    useEffect(()=>{
        getUserDD();
    },[]);




    let [deviceDetails,setDeviceDetails] = useState(null);
    


    const getDeviceDetails = useCallback(()=>{
        
        deviceServices.get(deviceId).then(res=>{
            setDeviceDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            
        })
    },[deviceId]);

    useEffect(()=>{
        getDeviceDetails();
    },[])

  


    return (
        <>
            {loading && <CommonLoadingSkeleton /> }
            <div className="row">
                
                <div className="col-md-9">
                    {deviceDetails && <h3 className="bg-success text-white p-2">{deviceDetails.Device_ID} | {deviceDetails.displayTitle} | {deviceDetails.location}</h3> }
                </div>

                <div className="col-md-3">
                    <span className="float-end">
                        <DeviceUserDetailsForm deviceId={deviceId} AppAlert={AppAlert} masters={masters} refreshList={getDeviceUserList} />
                    </span>
                </div>
            </div>
            {deviceUserList && 
                
                <div className="row">
                    {
                        deviceUserList.map(deviceUser=>
                            <div className="col-md-4" key={deviceUser.id}>

                                <div className="card mt-2">
                                    
                                    <div className="card-body">
                                        { getMasterLabel("users",deviceUser.userId) }
                                    </div>
                                    <div className="card-footer">
                                        <div className="float-end">
                                            <DeviceUserDetailsForm deviceId={deviceId} AppAlert={AppAlert} data={deviceUser} masters={masters} refreshList={getDeviceUserList} />
                                            <ConfirmDialog id={deviceUser.id} confirm={remove} >Are you sure wou want to unassign this user</ConfirmDialog>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        )
                    }
                </div>
            }
            {!deviceUserList && 
                <div className="row">
                    <div className="col-md-12 mt-4">
                        <h4>No user assigned with this device, click on add new button to add user to device</h4>
                    </div>
                </div>
            }
        </>
    )
}


export default withAlert(DeviceUserDetailsComponent)