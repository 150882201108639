import React, { useCallback, useEffect, useState } from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from "../../../layout/components/AppLayout"
import deviceServices from '../deviceServices'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import CommonLoadingSkeleton from '../../../components/CommonLoadingSkeleton'
import { Link } from 'react-router-dom'
import InstallDeviceComponent from '../components/InstallDevice/InstallDeviceComponent'
import BasicDetailsForm from '../components/BasicDetails/BasicDetailsForm'

import { BarChart, Settings } from '@mui/icons-material';

const CellLink = (props) =>{
    return (
        <>
            <Link className="btn btn-sm btn-danger " to={`/device/details/${props.data.id}`}> <Settings/> </Link>
            <Link className="btn btn-sm btn-primary ms-2" to={`/report/device/${props.data.Device_ID}`}> <BarChart/> </Link>
        </>
    )
}

const ActionCell = (props) =>{
    return (
        <>
            <BasicDetailsForm refreshList={props.refreshList} AppAlert={props.AppAlert} data={props.data} />
        </>
    )
}




const DeviceListPage = ({AppAlert}) => {

    const [rowData,setRowData] = useState(null);
    const [loading, setLoadingStatus] = useState(false);
    const [columnDefs,setColumnDefs] = useState(null);

    const getData = useCallback(() =>{
        setLoadingStatus(true);
        deviceServices.getList().then(res=>{
            setRowData(res.data);
            setColumnDefs(commonColDefs);
            setLoadingStatus(false);
        })
    },[]);


    const commonColDefs = [
        
        { field:"id",sortable: true, colId:"device.id",  filter:true, cellRenderer: CellLink, headerName: 'Action'},
        { field:"Device_ID", sortable: true, filter:true, headerName:'Device ID'  },
        { field:"User_Device_ID", sortable: true, filter:true, headerName:'User Device ID'  },
        { field:"displayTitle", sortable: true, filter:true, headerName:'Display Name'  },
        { field:"location", sortable: true, filter:true,   },
        { filter:false, cellRenderer: ActionCell, headerName: 'Update Status', width:150,  cellRendererParams: {  refreshList: getData, AppAlert:AppAlert }},
    ]

    const [defaultColDef] = useState({
        floatingFilter: true,
    })


    const updateData =(params) =>{
        console.log(params);
    }


    useEffect(()=>{
        getData()
    },[getData])
    return (
        <AppLayout>
            <div className="container">
                <div className="row m-2">
                    <div className="co-md-12">
                        <span className="float-end">
                            <InstallDeviceComponent AppAlert={AppAlert} />
                        </span>
                    </div>
                </div>

                <div className="row">
                          <div className="col-md-12">
                            {loading &&  <CommonLoadingSkeleton/> }
                            {! loading && rowData==null && <h3>No data Available</h3> }
                            <div className="ag-theme-alpine" style={{height: 400}}>
                              <AgGridReact
                                  rowData={rowData}
                                  columnDefs={columnDefs}
                                  defaultColDef={defaultColDef}
                                  onCellValueChanged = {(params)=>{ updateData(params) }}
                                  >
                              </AgGridReact>
                            </div>
                          </div>
                        </div>
            </div>
        </AppLayout>
    )
}


export default withAlert(DeviceListPage);