import moment from 'moment';
import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout';


import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';

import { CircularProgress } from '@mui/material';
import reportServices from '../reportServices';
import DataReport from '../components.js/DataReport';
import LogGrid from '../components.js/LogGrid';

function DeviceLog({AppAlert}) {

    let {deviceId} = useParams();

    const initialValues = {
        startDate:moment().subtract(1, 'days').format("YYYY-MM-DD"),
        endDate:moment().format("YYYY-MM-DD"),
    }

    const [loading,setLoadingStatus] = useState(false);
    const [channels,setChannels] = useState([]);
    const [data,setData] = useState([]);
    
    const onSubmit = (values, onSubmitProps) => {
        console.log("Searching ", values)

        reportServices.getLog(deviceId,values).then(res=>{
            setChannels(res.sensor);
            setData(res.data);
            console.log(res);
        }).catch(error=>{
            console.log(error);
        })
    }

    const validationRule = Yup.object({
        startDate: Yup.string().required("Required"),
        endDate: Yup.string().required("Required"),
    });
    

    return (
        <AppLayout>
            <div className="container-fluid">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                        <div className="row p-2">

                                                    <div className="col-md-9 col-xs-12 p-2" >
                                                        <div className="row">
                                                            <FormikControl control="input" formik={formik} type="date" 
                                                                label="Start Date" name="startDate" 
                                                                className="col-sm-6 col-xs-6 mt-2" 
                                                            />
                                                            <FormikControl control="input" formik={formik} type="date" 
                                                                label="End Date" name="endDate" 
                                                                className="col-sm-6 col-xs-6 mt-2" 
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                    {loading ? 
                                                        <CircularProgress/> 
                                                        :
                                                        <button className="btn btn-success float-end mt-2" type="submit">Search</button>
                                                    }
                                                    </div>
                                        </div>

                                </Form>
                            )
                        }
                    }
                </Formik>

                {data && data.length > 1 && !loading && <LogGrid channels={channels} chartData={data} />}
                
            </div>
        </AppLayout>
    )
}

export default withAlert(DeviceLog)