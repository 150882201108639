import api from "../../../../api/api";


const getApiConfig = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("network/device-api-config/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg,
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const updateApiConfig = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("network/update-api-config",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg,
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}






const apiConfigServices = {
    getApiConfig,
    updateApiConfig
}
export default apiConfigServices;