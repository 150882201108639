import React,{ useEffect,useState, useCallback } from 'react'
import { useSelector } from 'react-redux';
import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout'
import DeviceDashboardReport from '../components/DeviceDashboardReport';
import UserDeviceDetails from '../components/UserDeviceDetails';
import userDeviceServices from '../userDeviceServices'




function UserDeviceList({AppAlert}) {


    const [myDeviceList, setMydeviceList] = useState(null)
    const [loading, setLoadingStatus] = useState(false);

    const authUser = useSelector((state) => state.auth)
    

    const getData = useCallback(() =>{
        setLoadingStatus(true);
        userDeviceServices.get().then(res=>{
            if(res.data.length > 0){
                setMydeviceList(res.data);
            } else {
                setMydeviceList(null);
            }
        }).catch(error=>{
            //AppAlert("Something wend wrong","error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    },[]);


    useEffect(() => {
        getData()
    }, [])


    const getclassName = () =>{
        let cl = "";
        switch(myDeviceList.length){
        //switch(3){
            case 1:
                cl = "col-md-12";
                break;
            case 2:
                cl = "col-md-6";
                break;
            case 3:
                cl =  "col-md-4";
                break;
            default:
                cl = "col-md-3";
                break;
        }
        return cl;
    }


    return (
        <AppLayout>
            <div className="container-fluid mt-4">
                <div className="row">
                    
                    {authUser.id === 12 &&
                        <div className="col-md-6 offset-md-4 pb-2 text-center">
                            <span className="float-start">
                                {/*<img src="/iitj.png" style={{width:"60px",height:"60px"}}  />*/}
                            </span>
                            <h3 className="float-start mt-2 ms-2">Smart Water Supply Monitoring</h3>
                        </div>
                    }
                </div>
                {myDeviceList &&
                    <div className="row">
                            { myDeviceList.map(device=> 
                                <div className={getclassName()}>
                                    {/*<UserDeviceDetails device={device} AppAlert={AppAlert} />*/ }
                                    { <DeviceDashboardReport device={device} deviceCount={myDeviceList.length} /> }
                                </div>
                            )}
                    </div>
                }
                {!myDeviceList &&
                    <div className="row">
                        <div className="col-md-12">
                            No Device Available for you
                        </div>
                    </div>
                }
            </div>
        </AppLayout>
    )
}

export default withAlert(UserDeviceList)