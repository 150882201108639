import { SettingsInputComponent } from '@mui/icons-material';
import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import CommonLoadingSkeleton from '../../../../components/CommonLoadingSkeleton';
import withAlert from '../../../../hoc/withAlert'
import SensorComponent from './SensorComponent';
import sensorConfigServices from './sensorConfigServices';
import systemMasterServices from "../../../../master/systemMasterServices"


const SensorConfigComponent = ({deviceId,AppAlert}) => {

    const [loading, setLoadingStatus] = useState(false);
    const [sensorConfigList,setSensorConfigList] = useState(null);
    const [masters,setMasters] = useState(null); 

    const getMasterList = () => {
        systemMasterServices.get(["sensor-type","sensor-unit"]).then(res=>{
            setMasters(res);
            setSensorConfig();
        })
    }
    
    
    const setSensorConfig = useCallback(()=>{
        setLoadingStatus(true);
        sensorConfigServices.sensorConfig(deviceId).then(res=>{
            setSensorConfigList(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    },[deviceId])

    useEffect(()=>{
        getMasterList();
    },[])
    
    return (
        <>
            <div className="card mb-2">
                <div className="card-header bg-primary text-white"><SettingsInputComponent className="me-2"/>Sensor Configuration</div>
                <div className="card-body" id="sensor-config">
                    {loading && <CommonLoadingSkeleton/>}   
                    
                    {!loading && sensorConfigList && 
                       sensorConfigList.map(sensor=>
                        <div className="row" key={sensor.id}><SensorComponent deviceId={deviceId} AppAlert={AppAlert} sensor={sensor} masters={masters} /></div>
                       )
                    }
                </div>

            </div>
            
        </>
    )
}


export default withAlert(SensorConfigComponent);
