import { red } from "@mui/material/colors";
import api from "../../../../api/api";


const sensorConfig = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("device-setup/sensor-config/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg,
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const updateSensorConfig = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("device-setup/sensor-config",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg,
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}






const sensorConfigServices = {
    sensorConfig,
    updateSensorConfig,
}
export default sensorConfigServices;