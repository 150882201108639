import { CloudSync} from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react'
import CommonLoadingSkeleton from '../../../../components/CommonLoadingSkeleton';
import LabelValue from '../../../../components/InlineEdit/LabelValue'
import withAlert from '../../../../hoc/withAlert'
import deviceServices from '../../deviceServices';
import DeviceSyncDetailsForm from './DeviceSyncDetailsForm';


const  DeviceSyncDetailsComponent = ({deviceId, AppAlert}) =>{

    

    let [deviceSyncDetails,setDeviceSyncDetails] = useState(null);
    let [loading,setLoadingStatus] = useState(false);


    const getDeviceSyncDetails = useCallback(()=>{
        setLoadingStatus(true);
        deviceServices.get(deviceId).then(res=>{
            setDeviceSyncDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    },[deviceId]);

    useEffect(()=>{
        getDeviceSyncDetails();
    },[getDeviceSyncDetails])



    return (
        <>
            {loading && <CommonLoadingSkeleton /> }
            {deviceSyncDetails && 
                <div className="card mb-2">
                    <div className="card-header bg-primary text-white">
                        <span className="float-start"> <CloudSync/> <strong> Sync Details </strong></span>
                        <span className="float-end"><DeviceSyncDetailsForm refreshList={getDeviceSyncDetails} AppAlert={AppAlert} data={deviceSyncDetails}/></span>
                    </div>
                    <div className="card-body" id="sync-details">
                        <div className="row">
                            <div className="col-md-12"> <LabelValue label={"DATA SENDING INTERVAL(in minutes)"} value={deviceSyncDetails.dataInterval ? deviceSyncDetails.dataInterval:"N.A" } /></div>
                            <div className="col-md-12"> <LabelValue label={"ALERT CHECK INTERVAL(in seconds)"} value={deviceSyncDetails.alertInterval ? deviceSyncDetails.alertInterval:"N.A" } /></div>
                            <div className="col-md-12"> <LabelValue label={"No. OF ALARMS"} value={deviceSyncDetails.numbersOfAlarms ? deviceSyncDetails.numbersOfAlarms:"N.A" } /></div>
                            <div className="col-md-12"> <LabelValue label={"INTERVAL BETWEEN ALARMS (in minutes)"} value={deviceSyncDetails.alarmInterval ? deviceSyncDetails.alarmInterval:"N.A" } /></div>
                            <div className="col-md-12"> <LabelValue label={"ENABLE PERIODIC SMS:-"} value={(deviceSyncDetails.smsStatus === 1 ||  deviceSyncDetails.smsStatus === true )? "Yes":"No" } /></div>
                            <div className="col-md-12"> <LabelValue label={"PERIODIC SMS AT EVERY (in minutes)"} value={deviceSyncDetails.smsInterval ? deviceSyncDetails.smsInterval:"N.A" } /></div>
                            <div className="col-md-12"> <LabelValue label={"EMAIL TIME"} value={deviceSyncDetails.emailTime ? deviceSyncDetails.emailTime:"N.A" } /></div>
                        </div>
                    </div>
                </div>
            }
            
            

        </>
    )
}


export default withAlert(DeviceSyncDetailsComponent)