import { Add, Grid4x4, PeopleAlt, Print, SupportAgent, VoiceChat } from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppLayout from '../../layout/components/AppLayout';


export default function Dashboard() {

    const authUser = useSelector((state) => state.auth);

    const navigate = useNavigate();

    useEffect(() => {
      if(authUser.role <= 2){
            navigate("/device/list");
      } else {
            navigate("/my/device/list");
      }
    }, [])
    

    return(
        <AppLayout>
            
        </AppLayout>
    )
}



