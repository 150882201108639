
import { TextField } from '@mui/material';
import React, { useState } from 'react'
import installDeviceServices from './installDeviceServices';
import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Step, StepLabel, Stepper} from "@mui/material";
import LabelValue from '../../../../components/InlineEdit/LabelValue';
import { useNavigate } from 'react-router';
import { InstallDesktop } from '@mui/icons-material';

const InstallDeviceComponent = ({AppAlert}) => {

    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);
    const [jsonInput,setJsonInput]  = useState("");
    const [jsonErrorMsg ,setJsonErrorMsg ] = useState("");
    
    const [dataCols ,setDataCols ] = useState(null);
    const [deviceConfig,setDeviceConfig] = useState(null);

    const [activeStep,setActiveStep] = useState(0);

    const navigate = useNavigate(); 

    const handleJsonInput = (e) => {
        setJsonErrorMsg("");
        setJsonInput(e.target.value)
        setDataCols(null);
        try {
            let input = JSON.parse(e.target.value);
            if(input.Device_ID === undefined){
                setJsonErrorMsg("Invalid Json! Device_Id not Available");
                return false;
            }

            if(input.User_Device_ID === undefined){
                setJsonErrorMsg("Invalid Json! User_Device_ID not available");
                return false;
            }

            if(input.api_key === undefined){
                setJsonErrorMsg("Invalid Json! API_key not available");
                return false;
            }


            if(input.DeviceData === undefined){
                setJsonErrorMsg("Invalid Json! Device not available");
                return false;
            }


            let config = {
                Device_ID:input.Device_ID,
                User_Device_ID:input.User_Device_ID,
                api_key:input.api_key
            };

            setDeviceConfig(config);

            if(input.DeviceData !== undefined){
                let deviceData = input.DeviceData;
                let cols = [];
                Object.entries(deviceData).forEach(slaveDevice => {
                    const [deviceKey, deviceValue] = slaveDevice;
                    Object.entries(deviceValue['Slave_ID_Data']).forEach(slaveIdData => {
                        const [slaveDeviceKey, slaveDeviceValue] = slaveIdData;
                        cols.push(`${deviceKey}_${slaveDeviceKey}`);
                    });
                });
                setDataCols(cols);
            }
        }
        catch (e) { 
            setJsonErrorMsg("Invalid Json");
        }
    }


    const setupDevice = () =>{
        let payload = {
            deviceConfig:deviceConfig,
            dataCols:dataCols
        }
        //console.log(payload);
        setSavingStatus(true);

        installDeviceServices.setupDevice(payload).then(res=>{
            AppAlert(res.msg+". Redirecting you to device Details");
            setTimeout(()=>{
                navigate("/device/details/"+res.id);
            },3000)
        }).catch(error=>{
            AppAlert(error.msg+". Redirecting you to device Details","error")
            setTimeout(()=>{
                navigate("/device/details/"+error.id);
            },3000)
        }).finally(()=>{
            setDialogStatus(false);
        })
    }

    const addNew = ()=>{
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    const handleNext = () =>{
        if(activeStep < 3){
            setActiveStep(activeStep => ++activeStep)
        }
    }

    const handlePrevious = (formik) =>{
        if(activeStep > 0){
            setActiveStep(activeStep => --activeStep)
        }
    }


    return (
        <>
            <button type="button" className="btn btn-primary" onClick={addNew}><InstallDesktop/> Install New Device</button>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth=""
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Install Device"}</DialogTitle>
                <DialogContent>
                    <>
                        <Stepper activeStep={activeStep}>
                            <Step>
                                <StepLabel>Json Config</StepLabel>
                            </Step>
                            
                            <Step>
                                <StepLabel>Verify Channels</StepLabel>
                            </Step>

                            <Step>
                                <StepLabel>Install Device</StepLabel>
                            </Step>
                        </Stepper>

                        {activeStep === 0  && 
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <TextField id="outlined-basic" label="Sample Json" multiline variant="outlined" sx={{width: '100%'}}
                                        onChange={handleJsonInput}
                                        error={ jsonErrorMsg !== "" }  helperText={jsonErrorMsg}
                                    />
                                </div>
                            </div>
                        }

                        {activeStep === 1  && <>
                            {dataCols && 
                                <>
                                    <div className="row m-2">
                                        <div className="col-md-12 text-center">
                                            <h4>Available Inputs Channels</h4>
                                            {dataCols.map((col,index)=>
                                                <span key={index} style={{fontSize:16}} className="badge bg-dark m-2">{col}</span>
                                            )}
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                        }

                        {activeStep === 2  && <>
                            {deviceConfig && 
                                <>
                                    <div className="row mt-3 m-2">
                                        <div className="offset-md-3  col-md-6 text-center">
                                            <h4>Device Details</h4>
                                        </div>

                                        <div className="offset-md-3 col-md-6">
                                            <LabelValue label="Device_ID" value={deviceConfig.Device_ID} />
                                        </div>
                                        <div className="offset-md-3  col-md-6">
                                            <LabelValue label="User_Device_ID" value={deviceConfig.User_Device_ID} />
                                        </div>
                                        <div className="offset-md-3  col-md-6">
                                            <LabelValue label="api_key" value={deviceConfig.api_key} />
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                        }
                        
                    </>
                </DialogContent>
                <DialogActions>
                                <>
                                    {!savingInProgress && 
                                            <>
                                                <div className="col-md-12">
                                                    <button className="btn btn-light  float-end" type="button" onClick={handleClose}>Cancel</button>
                                                    
                                                    {activeStep < 2 &&
                                                        <button type="button" className="btn btn-danger float-end" onClick={() => handleNext ()} 
                                                            disabled={jsonInput === "" || jsonErrorMsg !== ""}
                                                        >   Next 
                                                        </button>
                                                    }

                                                    {activeStep == 2  &&
                                                        <button type="button" className="btn btn-danger float-end" onClick={setupDevice} >Install </button>
                                                    }
                                                    
                                                    {activeStep > 0 && <button type="button" className="btn btn-primary float-start" onClick={() => handlePrevious()}>Back </button> }
                                                    
                                                </div>
                                            </>
                                    }
                    
                                    {savingInProgress && 
                                            <>
                                                <div className="col-md-12"> <LinearProgress/> </div>
                                            </>
                                    }
                                </>
                </DialogActions>
            </Dialog>
        </>
    )
}


export default InstallDeviceComponent;
