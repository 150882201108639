import { AccountCircle, BarChart, CalendarMonthOutlined, Home,  Label,  ManageAccounts, MoreVert, Notifications, Settings, SettingsInputAntenna, SsidChart, TransferWithinAStation, WorkOutlined} from '@mui/icons-material';

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { Link, NavLink } from 'react-router-dom'


export default function Header() {
    
    const authUser = useSelector((state) => state.auth)
    const userId=  authUser.id;

    const navigate = useNavigate(); 
    

    useEffect(()=>{
        if(authUser.verified === 0){
          navigate("/auth/profile");
        }
    },[authUser])


    return (
        <>  { userId && 
            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top shadow">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        {authUser.id == 12 ?
                            <>
                            <img style={{height:"60px",width:"60px"}} src="/iitj.png" />
                            <span style={{color:"#1377a6", fontSize:"25px", fontWeight:"bold"}}> IIT Jodhpur</span>
                            </>
                            :
                            <span style={{color:"#1377a6", fontSize:"20px"}}>SenseTechno Solutions </span>
                        }
                        {/* <img style={{height:"60px",width:"60px"}} src="/iitj.png" /> */ }
                        {/* <span style={{color:"#1377a6", fontSize:"25px", fontWeight:"bold"}}> IIT Jodhpur</span>  */ }
                        {/* <span style={{color:"#1377a6", fontSize:"20px"}}> | SenseTechno Solutions</span> */ }
                        {/*<span style={{color:"#1377a6", fontSize:"20px"}}>SenseTechno Solutions {authUser.id} {authUser.firstName} </span> */}
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{fontSize:"18px"}}>
                                { authUser.role <= 3 && <>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" aria-current="page" to="/my/device/list"> <BarChart/> My Devices</NavLink>
                                    </li>

                                    { authUser.role <= 2 && 
                                        <li className="nav-item">
                                            <NavLink className="nav-link" aria-current="page" to="/my/device/health"><SsidChart/>   Device Health</NavLink>
                                        </li>
                                    }

                                    { authUser.role <= 2 && 
                                        <li className="nav-item">
                                            <NavLink className="nav-link" aria-current="page" to="/device/list"> <SettingsInputAntenna /> Manage Devices</NavLink>
                                        </li>
                                    }

                                    

                                    {/*
                                    
                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="!#" id="productDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <VoiceChat/> Products </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="productDropdown">
                                            <li><NavLink className="dropdown-item" to="/brand/list"> <Style/> Brands</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/model/list"> <Tag/> Models</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/product/list"> <LocalOffer/> Products</NavLink></li>
                                        </ul>
                                    </li>
                                    */}
                                </>}
                        </ul>
                        
                        <ul className="navbar-nav">
                            

                            { authUser.role < 2 && 
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="!#" id="adminControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVert/> </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="adminControllDropdown">
                                    <li><NavLink className="dropdown-item" to="/system-master/list"><Label /> Status Master</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/business/details"><WorkOutlined/> Business Details</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/list"><ManageAccounts/> Manage User Accounts</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/role"><Settings/> Manage User Role</NavLink></li>
                                </ul>
                            </li>
                            }



                            <li className="nav-item dropdown">
                                <a className="btn btn-danger" style={{borderRadius:"50px"}} href="!#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                    {authUser.firstName.charAt(0)}
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                                    <li><a className="dropdown-item" href="/auth/profile">My Profile</a></li>
                                    <li><a className="dropdown-item" href="/auth/change-password">Change Password</a></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><NavLink className="dropdown-item" to="/auth/logout">Logout</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        }
        </>
    )
}
