import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';

import { Add, Edit } from '@mui/icons-material';
import deviceServices from '../../deviceServices';


const  DeviceContactDetailsForm = ({refreshList, AppAlert, data=null}) => {
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        
        mobile1:"",
        mobile2:"",
        mobile3:"",
        email:"",
        
    }

    const onSubmit = (values, onSubmitProps) => {
        console.log(values);
        setSavingStatus(true)
        deviceServices.save(values).then(res =>{
            refreshList();
            AppAlert(res.msg)
            setDialogStatus(false);
        }).catch(error => {
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({
        mobile1: Yup.string().required("Required"),
        email: Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            mobile1:data.mobile1?data.mobile1:"",
            mobile2:data.mobile2?data.mobile2:"",
            mobile3:data.mobile3?data.mobile3:"",
            email:data.email?data.email:"",
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    return (
        <>
           {!data && <button className="btn btn-primary" onClick={addNew}><Add/> Add New</button>}
            {data &&
              <Tooltip title="Update">
                  <IconButton onClick={updateData} style={{padding:"0px"}}>
                      <Edit/>
                  </IconButton>
              </Tooltip>
            } 

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle className="text-center" >{"Communication Details"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">

                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Mobile 1" name="mobile1" 
                                                            className="col-sm-6 col-xs-6 p-2" 
                                                        />

                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Mobile 2" name="mobile2" 
                                                            className="col-sm-6 col-xs-6 p-2" 
                                                        />
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Mobile 2" name="mobile3" 
                                                            className="col-sm-6 col-xs-6 p-2" 
                                                        />
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Email" name="email" 
                                                            className="col-sm-6 col-xs-6 p-2" 
                                                        />

                                            <div className="col-md-3">
                                                <div className="row">
                                                        {/* 
                                                            masters && 
                                                            <div className="col-md-12  pt-2" >
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Branch" name="branch"  
                                                                    options={masters['branch']} multiple={false} 
                                                                />
                                                            </div>
                                                        */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        
                                        
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )
}


export default DeviceContactDetailsForm;