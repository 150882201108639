import React,{useState,useEffect} from 'react'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ExpandableCard from '../../../components/ExpandableCard';



function DataReport( {channels,chartData, selectedCount}) {



    const [defaultColDef] = useState({
        floatingFilter: true,
        width:200,
        resizable: true,
        filter:true,
        sortable: true,
    })

    const [columnDefs,setColumnDefs] = useState(null);

    const setupData = () =>{
        let colDefs = []
        colDefs.push({field:"time",headerName:"Date Time"});
        channels.forEach(channel => {
            colDefs.push(
                {
                field:channel.label,
                valueGetter: params =>  params.data[channel.label].toFixed(2)
                }
            );
        });
        setColumnDefs(colDefs);
    }

    useEffect(()=>{
        setupData();
    },[channels,chartData,])


    const [grid,setGrid] =  useState([]);
    const onGridReady =(params)=>{
        let g = {...params}
        setGrid(g);
    }

    const  exportData = () => {
        grid.api.exportDataAsCsv('report');
    }

    return (

        <div className="row mt-5 bg-white pt-3">
            <div className="col-md-12">
                <ExpandableCard title="Data Report" defaultOpen={true}>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <span className="float-end"><button type="button" className="btn btn-primary" onClick={exportData} >Export</button></span>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            {chartData && 
                                <div className="ag-theme-alpine" style={{height: 400, width:"auto"}}>
                                    <AgGridReact
                                        rowData={chartData}
                                        columnDefs={columnDefs}
                                        defaultColDef={defaultColDef}
                                        animateRows={true}
                                        onGridReady={(params)=>{ onGridReady(params)}}
                                        >
                                    </AgGridReact>
                                </div>
                            }
                        </div>
                    </div>
                </ExpandableCard>
            </div>
        </div>
    )
}

export default DataReport