import React, { useState } from 'react'
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';


import {
    Chart,
    BarSeries,
    LineSeries,
    ArgumentAxis,
    ValueAxis,
    Legend,
  } from '@devexpress/dx-react-chart-material-ui';

import { Animation } from '@devexpress/dx-react-chart';
import Paper from '@mui/material/Paper';
import { ArgumentScale, Stack } from '@devexpress/dx-react-chart';

/*
import { scaleBand } from '@devexpress/dx-chart-core';
import { ArgumentScale, Stack } from '@devexpress/dx-react-chart';
*/




  
  

export default function STWFTL0521001ReportComponent({data}) {
    

    const [columnDefs] = useState([
        { width:80, sortable: true,filter:true, field:"id", },
        { width:150, sortable: true,filter:true, field:"TimeDate", },
        { width:100, sortable: true,filter:true, field:"temp1", },
        { width:100, sortable: true,filter:true, field:"temp2", },

        { width:100, sortable: true,filter:true, field:"high1", },
        { width:100, sortable: true,filter:true, field:"high2", },

        { width:100, sortable: true,filter:true, field:"low1", },
        { width:100, sortable: true,filter:true, field:"low2", },
        
        
        
    ])

    
    return (
            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-12">
                            <Paper>
                                <Chart
                                data={data} height="250"
                                >
                                    <ArgumentAxis />
                                    <ValueAxis />
                                    <Legend />
                                    <Animation />
                                    <LineSeries
                                        valueField="temp1"
                                        argumentField="TimeDate"
                                        name="Temp 1"
                                    />

                                    <Stack />
                                </Chart>
                            </Paper>
                        </div>
                        <div className="col-md-12">
                            <Paper>
                                <Chart
                                data={data} height="250"
                                >
                                    <ArgumentAxis />
                                    <ValueAxis />
                                    <Legend />
                                    <Animation />
                                    <LineSeries
                                        valueField="temp2"
                                        argumentField="TimeDate"
                                        name="Temp 2"
                                    />

                                    <Stack />
                                </Chart>
                            </Paper>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="co-md-12">
                            {data && 
                                <>
                                    <h3>Data Log</h3>
                                    <div className="ag-theme-alpine" style={{height: 400, width:"auto"}}>
                                        <AgGridReact
                                            rowData={data}
                                            columnDefs={columnDefs}>
                                        </AgGridReact>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                
            </div>
  )
}
