import React,{useCallback, useEffect, useState} from 'react'
import { LinearProgress } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';
import { Cable, Sensors } from '@mui/icons-material';
import sensorConfigServices from './sensorConfigServices';

const SensorComponent = ({deviceId, AppAlert,sensor,masters }) => {

    const [formValues, setFormValues] = useState(null);
    const [savingInProgress, setSavingStatus] = useState(false);
    

    const initialValues = {
        sensorType:"",
        sensorUnit:"",
        lowAlarmStatus:"",
        lowAlarmValue:"",
        highAlarmStatus:"",
        highAlarmValue:"",
    }



    const onSubmit = (values, onSubmitProps) => {

        let payload = {
            id:deviceId,
            data:values,
        }
        setSavingStatus(false);
        sensorConfigServices.updateSensorConfig(payload).then(res =>{
            onSubmitProps.resetForm({ values });  
            AppAlert(res.msg)
        }).catch(error => {
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setSavingStatus(false);
        })
        
    }

    const validationRule = Yup.object({
        sensorType:Yup.string().required("Required"),
        sensorUnit:Yup.string().required("Required"),
        lowAlarmStatus:Yup.string().required("Required"),
        lowAlarmValue:Yup.string().required("Required"),
        highAlarmStatus:Yup.string().required("Required"),
        highAlarmValue:Yup.string().required("Required"),
    });

    const updateData = ()=>{
        let data = sensor;
        let autoFill = {
            id:data.id,
            sensorType:data.sensorType,
            sensorUnit:data.sensorUnit,
            lowAlarmStatus:data.lowAlarmStatus,
            lowAlarmValue:data.lowAlarmValue,
            highAlarmStatus:data.highAlarmStatus,
            highAlarmValue:data.highAlarmValue,
        }
        setFormValues(autoFill);
    }

    useEffect(()=>{
        updateData();
    },[])

    return(
        <div className="col-md-12 ">
            <div className="card mb-1">
                <div className="card-header bg-dark text-white">
                    <Sensors/> <Cable className="me-2"/> <strong>{sensor.channel}</strong>
                </div>
                
                <div className="card-body">
                    

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {

                            return (
                                <Form>

                                        <div className="row p-2">
                                            { masters && 
                                                <div className="col-sm-6 pt-3" >
                                                    <FormikControl control="autoselect" formik={formik} 
                                                        label="Sensor Type" name="sensorType"  
                                                        options={masters['sensor-type']} multiple={false} 
                                                    />
                                                </div>
                                            }
                                            { masters && 
                                                <div className="col-sm-6 pt-3" >
                                                    <FormikControl control="autoselect" formik={formik} 
                                                        label="Sensor Unit" name="sensorUnit"  
                                                        options={masters['sensor-unit']} multiple={false} 
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className="row p-2">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <FormikControl control="switch" formik={formik}
                                                            label="Low Alarm Status" name="lowAlarmStatus"
                                                        />
                                                    </div>
                                                    {(formik.values.lowAlarmStatus === 1 || formik.values.lowAlarmStatus === true)  && 
                                                        <>
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Low Alarm Value" name="lowAlarmValue" 
                                                            className="col-sm-12 p-2" 
                                                        />
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <FormikControl control="switch" formik={formik}
                                                            label="High Alarm Status" name="highAlarmStatus"
                                                        />
                                                    </div>
                                                    {(formik.values.highAlarmStatus === 1 || formik.values.highAlarmStatus === true)  && 
                                                        <>
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="High Alarm Value" name="highAlarmValue" 
                                                            className="col-sm-12 p-2" 
                                                        />
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    
                                        <div className="row">
                                            {!savingInProgress && formik.dirty &&
                                                <>
                                                    <button className="btn btn-success" type="submit">Save</button>
                                                </>
                                            }
                                        </div>
                                </Form>
                            )
                        }
                    }
                </Formik>



                </div>

                {/* 
                    <div className="card-body">
                        <p>{JSON.stringify(sensor)}</p> 
                    </div>
                */}
            </div>
        </div>
    )
}

export default SensorComponent