import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';

import { Add, Edit } from '@mui/icons-material';
import deviceServices from '../../deviceServices';



const  BasicDetailsForm = ({refreshList, AppAlert, data=null}) => {
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        User_Device_ID	:"",
        api_key:"",
        deviceName:"",
        displayTitle:"",
        location:"",
        camStatus:0,
        camLink:"",
        
    }

    const onSubmit = (values, onSubmitProps) => {
        console.log(values);
        setSavingStatus(true)
        deviceServices.save(values).then(res =>{
            refreshList();
            AppAlert(res.msg)
            setDialogStatus(false);
        }).catch(error => {
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({
        api_key: Yup.string().required("Required"),
        User_Device_ID: Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            User_Device_ID:data.User_Device_ID,
            api_key:data.api_key,
            deviceName:data.deviceName?data.deviceName:"",
            displayTitle:data.displayTitle?data.displayTitle:"",
            location:data.location?data.location:"",
            camStatus:data.camStatus,
            camLink:data.camLink,
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    return (
        <>
           {!data && <button className="btn btn-primary" onClick={addNew}><Add/> Add New</button>}
            {data &&
              <Tooltip title="Update">
                  <IconButton onClick={updateData} style={{padding:"0px"}}>
                      <Edit/>
                  </IconButton>
              </Tooltip>
            } 

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle className="text-center" >{"Device# : "+ data.Device_ID}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">


                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="User Device ID" name="User_Device_ID" 
                                                            className="col-sm-6 col-xs-6 p-2" 
                                                        />
                                                    
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="API Key" name="api_key" 
                                                            className="col-sm-6 col-xs-6 p-2" 
                                                        />
                                                    
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Device Name" name="deviceName" 
                                                            className="col-sm-6 col-xs-6 p-2" 
                                                        />

                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Display Name" name="displayTitle" 
                                                            className="col-sm-6 col-xs-6 p-2" 
                                                        />

                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Location" name="location" 
                                                            className="col-sm-12 col-xs-12 p-2" 
                                                        />
                                                    
                                                        <FormikControl control="switch" formik={formik} 
                                                            label="Camera Availabe" name="camStatus" 
                                                            className="col-sm-6 col-xs-6 p-2" 
                                                        />
                                                        
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Camera link" name="camLink" 
                                                            className="col-sm-6 col-xs-6 p-2" 
                                                        />
                                                    
                                                

                                            
                                            <div className="col-md-3">
                                                <div className="row">
                                                        {/* 
                                                            masters && 
                                                            <div className="col-md-12  pt-2" >
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Branch" name="branch"  
                                                                    options={masters['branch']} multiple={false} 
                                                                />
                                                            </div>
                                                        */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        
                                        
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )
}


export default BasicDetailsForm;