import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';

import { Add, Edit } from '@mui/icons-material';
import deviceUserServices from './deviceUserServices';



const  DeviceUserDetailsForm = ({refreshList, AppAlert, masters, deviceId, data=null}) => {
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        
        deviceId:deviceId,
        userId:"",
        
        
        
    }

    const onSubmit = (values, onSubmitProps) => {
        console.log(values);
        setSavingStatus(true)
        deviceUserServices.save(values).then(res =>{
            refreshList();
            AppAlert(res.msg)
            setDialogStatus(false);
        }).catch(error => {
            console.log(error);
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({
        
        userId: Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            deviceId:data.deviceId,
            userId:data.userId
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    return (
        <>
           {!data && <button className="btn btn-primary" onClick={addNew}><Add/> Assign New User</button>}
            {data &&
              <Tooltip title="Update">
                  <IconButton onClick={updateData} style={{padding:"0px"}}>
                      <Edit/>
                  </IconButton>
              </Tooltip>
            } 

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle className="text-center" >{"Communication Details"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">

                                                        

                                                        
                                                        
                                        {masters &&  
                                                        <FormikControl control="autoselect" formik={formik} 
                                                            label="Select User" name="userId"  
                                                            options={masters['users']} multiple={false} 
                                                            className="col-md-6 col-xs-6 p-2"
                                                        />
                                                    }

                                            <div className="col-md-3">
                                                <div className="row">
                                                        {/* 
                                                            masters && 
                                                            <div className="col-md-12  pt-2" >
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Branch" name="branch"  
                                                                    options={masters['branch']} multiple={false} 
                                                                />
                                                            </div>
                                                        */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        
                                        
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )
}


export default DeviceUserDetailsForm;