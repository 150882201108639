import api from "../../../../api/api";



const get = (id) => {
    return new Promise((resolve, reject)=>{
          api.get("device-user/"+id).then( (res)=>{
            if(res.data){
                resolve(res.data);
            } else {
                reject({
                    msg: "Somthing Went Wrong"
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}




const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/device-user",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const remove = (device_user_id) =>{
    return new Promise((resolve, reject)=>{
        api.delete("/device-user/"+device_user_id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}




const deviceUserServices = {
    get,
    save,
    remove
 }
 
 export default deviceUserServices;