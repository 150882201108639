import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';


import { Line } from 'react-chartjs-2';



ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );
  

function DeviceReportNew() {





  return (
    <div>

        <Line
            datasetIdKey='id'
            data={{
                labels: ['Jun', 'Jul', 'Aug', 'Sept'],
                datasets: [
                {
                    label: 'DataSet One',
                    data: [5, 6, 7, 1],
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    
                },
                {
                    label: 'DataSet 2',
                    data: [3, 2, 1,6],
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                ],
            legend: {
                position: 'right',
            },
            }}
        />
    </div>
  )
}

export default DeviceReportNew