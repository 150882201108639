import { Cancel, CheckCircle, RemoveRedEye, VisibilityOff } from '@mui/icons-material';
import React,{useState,useEffect} from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import deviceHealthServices from '../deviceHealthServices';
import moment from 'moment';
import { IconButton, Tooltip } from '@mui/material';




const DeviceDetails = ({device, getDeviceList}) =>{

    const updateWatchListStatus = (watchListStatus) =>{
        let payload ={
            id :device.id,
            watchListStatus:watchListStatus
        }
        deviceHealthServices.updateWatchListstatus(payload).then(res=>{
            //console.log(res.data)
            getDeviceList();
        }).catch(error=>{
            console.log(error);
        })
    }
    
    return(
        <tr>
            <td>{device.Device_ID}</td>
            <td>
                {
                    device.watchListStatus === 0 ?
                    <Tooltip title="Click here Add to watchlist">
                        <IconButton className="text-danger" onClick={()=>updateWatchListStatus(1)} style={{padding:"0px"}}>
                            <VisibilityOff />
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title="Click here to Remove from watchlist">
                        <IconButton className="text-success" onClick={()=>updateWatchListStatus(0)} style={{padding:"0px"}}>
                            <RemoveRedEye/>
                        </IconButton>
                    </Tooltip>
                } 
            </td>
            <td>{device.deviceName}</td>
            <td>{ moment(device.created_at).format("ll")} <br/> <strong> { moment(device.created_at).fromNow()} </strong> </td>
            <td>{device.location}</td>
            <td>{device.dataInterval} Min</td>
            <td>{device.lastRecordTime}</td>
            <td>
                {device.health === -1 && "No Record Yet"}
                {device.health === 0 && <Cancel className="text-danger" />}
                {device.health === 1 && <CheckCircle className="text-success" />}
            </td>
        </tr>
    )
    

}


function DeviceHealthList() {

    const [deviceList,setDeviceList] = useState(null);

    const [listType,setListType] = useState("watchlist");

    const getDeviceList = () =>{
        console.log("here");
        deviceHealthServices.get().then(res=>{
            setDeviceList(res.data);
        }).catch(e=>{
            console.log(e);
        })
    }

    useEffect(()=>{
        getDeviceList();
    },[])

    return (
        <AppLayout>
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-md-12">
                        <div class="d-flex justify-content-around">
                            <button className="btn btn-lg btn-info nav-pill" onClick={()=>setListType("watchlist")} >Watchlist</button>
                            <button className="btn btn-lg btn-primary" onClick={()=>setListType("all")} >All Device</button>
                            <button className="btn btn-lg btn-danger nav-pill" onClick={()=>setListType("inactive")} >All Inactive Device</button>
                            <button className="btn btn-lg btn-success" onClick={()=>setListType("active")} >All Active Device</button>
                        </div>
                        <hr/>
                    </div>
                    <div className="col-md-12">
                        {deviceList &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td>Device ID</td>
                                        <td></td>
                                        <td>Device Name </td>
                                        <td>Installed on </td>
                                        <td>Device Location </td>
                                        <td>Data Interval </td>
                                        <td>Last Record Time </td>
                                        <td>Device Health </td>
                                    </tr>
                                </thead>
                                
                                
                                {listType == "watchlist" &&
                                    <tbody>
                                        {deviceList.map(device =>
                                            device.watchListStatus === 1 &&
                                            <DeviceDetails device={device}  getDeviceList={getDeviceList} />
                                        )}
                                    </tbody>
                                }

                                {listType == "all" &&
                                    <tbody>
                                        {deviceList.map(device =>
                                            <DeviceDetails device={device}  getDeviceList={getDeviceList} />
                                        )}
                                    </tbody>
                                }

                                {listType == "inactive" &&
                                    <tbody>
                                        {deviceList.map(device =>
                                            device.health === 0 &&
                                            <DeviceDetails device={device}  getDeviceList={getDeviceList} />
                                        )}
                                    </tbody>
                                }

                                {listType == "active" &&
                                    <tbody>
                                        {deviceList.map(device =>
                                            device.health === 1 &&
                                            <DeviceDetails device={device}  getDeviceList={getDeviceList} />
                                        )}
                                    </tbody>
                                }

                            </table>
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default DeviceHealthList