import React, { useState } from 'react'
import { useParams } from 'react-router'
import AppLayout from '../../../layout/components/AppLayout'
import BasicDetailsComponent from '../components/BasicDetails/BasicDetailsComponent';
import DeviceContactDetailsComponent from '../components/DeviceContactDetails/DeviceContactDetailsComponent';
import DeviceLayout from '../components/DeviceLayout'
import DeviceSyncDetailsComponent from '../components/DeviceSyncDetails/DeviceSyncDetailsComponent';
import SensorConfigComponent from '../components/SensorConfig/SensorConfigComponent';


import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { CloudSync, ContactMail, DevicesOther, PeopleAlt, SettingsInputComponent } from '@mui/icons-material';
import DeviceUserDetailsComponent from '../components/DeviceUserDetails/DeviceUserDetailsComponent';

const DeviceConfig = ({AppAlert}) =>{
    let { deviceId } = useParams();

    let hash = window.location.hash.substring(1);

    const [value, setValue] = useState('device-sync-details');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
      <AppLayout>

        
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="bg-dark text-white">
                {/* variant="scrollable" scrollButtons="auto" */}
                <TabList onChange={handleChange} textColor="inherit" indicatorColor="secondary"  centered={true}>
                    {/* <Tab icon={<DevicesOther />} label="Basic Details" value="basic-details" /> */ }
                    <Tab icon={<CloudSync />} label="Device Sync Details" value="device-sync-details" />
                    <Tab icon={<ContactMail />}label="Communications Details" value="communication" />
                    <Tab icon={<SettingsInputComponent />} label="Sensor Configuration" value="sensor-config" />
                    {/* <Tab icon={<PeopleAlt />} label="Device User" value="device-user" /> */ }
                </TabList>
                </Box>
                <div className="container">
                    {/* <TabPanel value="basic-details"><BasicDetailsComponent deviceId={deviceId} /></TabPanel> */}
                    <TabPanel value="device-sync-details"><DeviceSyncDetailsComponent deviceId={deviceId} /></TabPanel>
                    <TabPanel value="communication"><DeviceContactDetailsComponent deviceId={deviceId} /></TabPanel>
                    <TabPanel value="sensor-config"><SensorConfigComponent deviceId={deviceId} /></TabPanel>
                    {/* <TabPanel value="device-user"><DeviceUserDetailsComponent deviceId={deviceId} /></TabPanel> */ }
                </div>
            </TabContext>
        


          {/*
          <DeviceLayout deviceId={deviceId}>
            
            
            
            
            
          </DeviceLayout>
          */}
      </AppLayout>
    )
}

export default DeviceConfig;