import moment from 'moment';
import React,{useState,useEffect,useCallback} from 'react'
import { Link } from 'react-router-dom';
import withAlert from '../../../hoc/withAlert'
import MQcomponent from '../../MQTT/MQcomponent';
import reportServices from '../reportServices'

const LastReading = ({deviceId}) => {

    const [reading,setReading] = useState(null);
    const [sensorConfig,setSensorConfig] = useState(null);

    const getLastReading = () =>{
        reportServices.getLastReading(deviceId).then(res=>{
            if(res.data.length > 0){
                setReading(res.data);
                setSensorConfig(res.sensor);
            } else {
                setReading(null)
                setSensorConfig(null);
            }
        })
    }

    useEffect(() => {
        getLastReading();
        const timer = setInterval(()=>{
            if (!document.hidden) {
                getLastReading();
            } 
        },60000)
        return ()=> clearInterval(timer)
    },[] )



    const prepareVale = (value) =>{
        let v =  parseFloat(value)
        if(isNaN(v)){
            return value
        }
        //return v % 1 == 0?v:v.toFixed(3);
        return value
    }
    

    return (
        
        <>
                {sensorConfig && 
                        <div className="row mb-5">
                            <div className="col-md-12 text-center mt-5 pt-4 bg-white ">
                                <p className="text-success" style={{fontSize:24}}>Last updated at <strong>{ moment(reading[0].created_at).format("LLLL") }</strong></p>
                            </div>
                            
                            <div className="col-md-12 table-responsive bg-white">
                                <table className="table table-bordered" style={{fontSize:"16px"}}>
                                    <thead>
                                        <tr>
                                            {sensorConfig.map(sensor=>
                                                <th key={sensor.channel}>{sensor.sensorType}</th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {reading.map(data=>
                                            <tr key={data.id}>
                                                {sensorConfig.map(sensor=>
                                                    <td key={sensor.channel+"_"+data.id}>{ prepareVale(data[sensor.channel]) } {sensor.sensorUnit}</td>
                                                )}
                                            </tr>
                                        )}
                                        
                                    </tbody>

                                </table>
                            </div>
                            <div className="col-md-12 py-3 table-responsive bg-white text-center">
                                
                                <Link to={"/report/log/"+deviceId} className="btn btn-primary">View Log</Link>
                                { deviceId && <MQcomponent deviceId={deviceId} /> }
                                
                            </div>
                        </div>
                    
                }

            
        </>
    )
}

export default withAlert(LastReading)