import React from 'react'
import Footer from './Footer'
import Header from './Header'

export default function AppLayout({children, background=null}) {

    
    
  return (
    <>
        {/* 
            style={{backgroundImage:" url(../../../global-ams.jpg)"}}
        */}
        <Header/>
            {background && 
                <div className="main p-2"  style={{backgroundImage:" url(../../../waether.png)"}}>
                    {children}
                </div>
            }
            
            {!background && 
                <div className="main"  >
                    {children}
                </div>
            }
                
        <Footer/>

    </>
  )
}
