import React,{useEffect, useState} from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);


function GraphReport({channels,chartData, selectedCount}) {

    const [dataSets, setDataSets] = useState(null)
    const [dataLabels, setDataLabels] = useState(null)

    const prepairDataSets = () => {
        let sets  =  [];
        let labels =  [];



        let backgroundColor = [
            //'rgba(1, 1, 1, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
        ];
        let  borderColor =[
            //'rgba(1, 1, 1, 0.1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
        ];


        chartData.forEach(serise=>{
            labels.push(serise.time)
        })

        let colorI = 0;
        channels.forEach(channel => {
            let data  = [];
            if(channel.status == true){
                chartData.forEach(serise=>{
                    data.push(serise[channel.label])
                });
                sets.push({label:channel.label,data:data,backgroundColor:backgroundColor[colorI], borderColor:borderColor[colorI],fill:true})
                colorI++;
            }
        });

        //console.log(sets);

        setDataLabels(labels);
        setDataSets(sets);
    }


    useEffect(() => {
        //console.log(chartData);

        prepairDataSets();

    }, [channels,chartData, selectedCount])
    
    
    return (
        <>
        <div className="row bg-white rounded shadow">
            <div className="col-12" >

            
            {dataSets && 
                <Line
                    options={{
                        responsive: true,
                        stacked: false,
                        interaction: {
                            mode: 'index',
                            intersect: false,
                        },
                    }}
                    data={
                        {
                            labels: dataLabels,
                            datasets: dataSets
                    }
                }
                />
            }

            </div>
        </div>
    
        </>
    )
}

export default GraphReport