import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';

import { Add, Edit } from '@mui/icons-material';
import deviceServices from '../../deviceServices';


const  DeviceSyncDetailsForm = ({refreshList, AppAlert, data=null}) => {
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        
        dataInterval:"",
        alertInterval:"",
        numbersOfAlarms:"",
        alarmInterval:"",
        smsStatus:"",
        smsInterval:"",
        emailTime:"",
        
    }

    const onSubmit = (values, onSubmitProps) => {
        console.log(values);
        setSavingStatus(true)
        deviceServices.save(values).then(res =>{
            refreshList();
            AppAlert(res.msg)
            setDialogStatus(false);
        }).catch(error => {
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({
        

        dataInterval: Yup.string().required("Required"),
        alertInterval: Yup.string().required("Required"),
        numbersOfAlarms: Yup.string().required("Required"),
        alarmInterval: Yup.string().required("Required"),
        //smsStatus: Yup.string().required("Required"),
        smsInterval: Yup.string().required("Required"),
        emailTime: Yup.string().required("Required"),

    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            
            dataInterval:data.dataInterval,
            alertInterval:data.alertInterval,
            numbersOfAlarms:data.numbersOfAlarms,
            alarmInterval:data.alarmInterval,
            smsStatus:data.smsStatus,
            smsInterval:data.smsInterval,
            emailTime:data.emailTime,
            
            
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    return (
        <>
           {!data && <button className="btn btn-primary" onClick={addNew}><Add/> Add New</button>}
            {data &&
              <Tooltip title="Update">
                  <IconButton onClick={updateData} style={{padding:"0px"}}>
                      <Edit/>
                  </IconButton>
              </Tooltip>
            } 

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle className="text-center" >{"Sync Details"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">




                                        <FormikControl control="input" formik={formik} type="text" label="DATA SENDING INTERVAL(in minutes)" name="dataInterval" className="col-sm-6 col-xs-6 p-2" />
                                        <FormikControl control="input" formik={formik} type="text" label="ALERT CHECK INTERVAL(in seconds)" name="alertInterval" className="col-sm-6 col-xs-6 p-2" />
                                        <FormikControl control="input" formik={formik} type="text" label="No. OF ALARMS" name="numbersOfAlarms" className="col-sm-6 col-xs-6 p-2" />
                                        <FormikControl control="input" formik={formik} type="text" label="INTERVAL BETWEEN ALARMS (in minutes)" name="alarmInterval" className="col-sm-6 col-xs-6 p-2" />
                                        <FormikControl control="switch" formik={formik} type="text" label="ENABLE PERIODIC SMS:-" name="smsStatus" className="col-sm-6 col-xs-6 p-2" />
                                        <FormikControl control="input" formik={formik} type="text" label="PERIODIC SMS AT EVERY (in minutes)" name="smsInterval" className="col-sm-6 col-xs-6 p-2" />
                                        <FormikControl control="input" formik={formik} type="time" label="EMAIL TIME" name="emailTime" className="col-sm-6 col-xs-6 p-2" />

                                            <div className="col-md-3">
                                                <div className="row">
                                                        {/* 
                                                            masters && 
                                                            <div className="col-md-12  pt-2" >
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Branch" name="branch"  
                                                                    options={masters['branch']} multiple={false} 
                                                                />
                                                            </div>
                                                        */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        
                                        
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )
}


export default DeviceSyncDetailsForm;