import React,{useState,useEffect} from 'react'
import dashboardConfigServices from './dashboardConfigServices';
import { CheckBox, CheckBoxOutlineBlank} from '@mui/icons-material';
import withAlert from '../../../../hoc/withAlert';

function DeviceDashboardConfig({deviceId, AppAlert}) {
    const [dashboardConfig,setDashboardConfig] = useState(null);
    const [sensorConfig,setSensorConfig] = useState(null);
    const getDashboardConfig = () =>{
        dashboardConfigServices.getDashboardConfig(deviceId).then(res=>{
            setDashboardConfig(res.data.dashboardConfig);
            setSensorConfig(res.data.dashboardConfig.sensorConfig);
        }).catch(error=>{
            console.log(error)
        })
    }

    useEffect(()=>{
        getDashboardConfig()
    },[deviceId])

    const [modified,setModified] =  useState(false);
    const updateSensor = (i,key,value) => {
        setModified(true);
        let _sensorConfig = [...sensorConfig];
        _sensorConfig[i][key] = value;
        setSensorConfig(_sensorConfig)
    }

    const saveConfiguration = () =>{
        let payload  = {
            deviceId : deviceId,
            sensorConfig:sensorConfig,
            
        }
        console.log(payload);
        dashboardConfigServices.updateDashboardConfig(payload).then(res=>{
            console.log(res);
            setModified(false);
            AppAlert("Updated Successfully")
        }).catch(error=>{
            console.log(error)
        })
    }

    return (
        dashboardConfig &&
        <div className="row">
            <div className="col-md-8">
                <div className="card">
                    <div className="card-header"><h3>Dashboard Config ({dashboardConfig.Device_ID})</h3></div>
                    <div className="card-body table-responsive">
                        <table className="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>Channel</th>
                                    <th>Unit</th>
                                    <th>Sensor</th>
                                    <th>Min / Max</th>
                                    {/*<th>Avg</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {sensorConfig.map((sensor,i)=>
                                    <tr>
                                        <td>{sensor.channel}</td>
                                        <td>{sensor.sensorType}</td>
                                        <td>{sensor.sensorUnit}</td>
                                        <td>
                                            { sensor.minMax === 1 ?
                                                <button className="btn text-success" onClick={()=>updateSensor(i,"minMax",0)} ><CheckBox/></button>
                                                :
                                                <button className="btn text-danger" onClick={()=>updateSensor(i,"minMax",1)}><CheckBoxOutlineBlank/></button>
                                            }
                                        </td>
                                        {/*
                                        <td>
                                            { sensor.avg === 1 ?
                                                <button className="btn text-success" onClick={()=>updateSensor(i,"avg",0)} ><CheckBox/></button>
                                                :
                                                <button className="btn text-danger" onClick={()=>updateSensor(i,"avg",1)}><CheckBoxOutlineBlank/></button>
                                            }
                                        </td>
                                        */}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <button className="btn btn-primary btn-lg w-100" disabled={!modified} onClick={saveConfiguration} >Save</button>
            </div>
        </div>
    )
}

export default withAlert(DeviceDashboardConfig)