import api from "../../api/api";


const getDeviceSensorListDD = (device) =>{
    return new Promise((resolve, reject)=>{
        api.get("device/sensor-list/"+device).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getReport = (q) =>{
    return new Promise((resolve, reject)=>{
        api.get("report/device"+q).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getDahboardReport = (q) =>{
    return new Promise((resolve, reject)=>{
        api.get("report/dashboard/device?deviceId="+q+"&sensor=1").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getLastReading = (deviceId) =>{
    return new Promise((resolve, reject)=>{
        api.get("report/last-reading/"+deviceId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getMinMaxReading = (deviceId,startDate,endDate) =>{
    return new Promise((resolve, reject)=>{
        api.get("report/min-max-reading/"+deviceId+"?startDate="+startDate+"&endDate="+endDate).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getLog = (deviceId,{startDate,endDate}) =>{
    return new Promise((resolve, reject)=>{
        api.get("report/log/"+deviceId+"?startDate="+startDate+"&endDate="+endDate).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const reportServices = {
    getReport,
    getDahboardReport,
    getDeviceSensorListDD,
    getLastReading,
    getMinMaxReading,
    getLog,
}

export default reportServices;