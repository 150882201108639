import React, { useCallback, useEffect, useState } from 'react'
import AppLayout from '../../../layout/components/AppLayout'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';




import reportServices from '../reportServices';
import STWFTL0521001ReportComponent from '../components.js/STWFTL0521001ReportComponent';
import STWFMBA0122001ReportComponent from '../components.js/STWFMBA0122001ReportComponent';

  
export default function ReportList() {

    const [deviceId, setDeviceId] = useState('');

    const [startDate, setStartDate] = useState(dayjs('2022-10-01'));
    const [endDate, setEndDate] = useState(dayjs('2022-10-19'));
    const [dataLog,setDataLog] = useState([]);

    const getData = useCallback(()=>{
        if(deviceId && deviceId != ""){
            let q = `?deviceId=${deviceId}&startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format("YYYY-MM-DD")}`;
            reportServices.getReport(q).then(res=>{
                if(deviceId === "STWFTL0521001"){
                    let chartData = res.data.log.map(d=>{
                        let newData =  d;
                        newData['temp1'] = parseFloat(d.temp1);
                        newData['temp2'] = parseFloat(d.temp2);
    
                        newData['high1'] = parseFloat(d.high1);
                        newData['high2'] = parseFloat(d.high2);
    
                        newData['low1'] = parseFloat(d.low1);
                        newData['low2'] = parseFloat(d.low2);
                        
                        return newData;
                    })
                    setDataLog(chartData);
                } else {
                    console.log("I am here");
                    let chartData = res.data.log.map(d=>{
                        let newData =  d;

                        newData['CH1'] = parseFloat(d.CH1);
                        newData['CH2'] = parseFloat(d.CH2);
                        newData['CH3'] = parseFloat(d.CH3);
                        newData['CH4'] = parseFloat(d.CH4);
                        newData['CH5'] = parseFloat(d.CH5);
                        newData['CH6'] = parseFloat(d.CH6);
                        newData['CH7'] = parseFloat(d.CH7);
                        newData['CH8'] = parseFloat(d.CH8);
                        console.log("hew",newData);
                        return newData;
                    })
                    setDataLog(chartData);
                }
            }).catch(error=>{

            })
        }
        
    },[deviceId,startDate,endDate])

    useEffect(()=>{
        getData();
    },[getData]);

    return (
      <AppLayout>
          <div className="container">
            <div className="row bg-light ps-2 pe-2 pb-3 mt-2 rounded">
                <div className="col-md-6 mt-4">
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="demo-simple-select-label">Select Device</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={deviceId}
                            label="Device"
                            onChange={(e)=>setDeviceId(e.target.value)}
                        >
                        <MenuItem value={"STWFTL0521001"}>STWFTL0521001 (GANGWAR1)</MenuItem>
                        <MenuItem value={"STWFMBA0122001"}>STWFMBA0122001 (COLDROOM5)</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="col-md-3 mt-4">
                        <MobileDatePicker
                            label="From"
                            inputFormat="MM/DD/YYYY"
                            value={startDate}
                            onChange={(newValue)=>setStartDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                    <div className="col-md-3 mt-4">
                        <MobileDatePicker
                            label="To"
                            inputFormat="MM/DD/YYYY"
                            value={endDate}
                            onChange={(newValue)=>setEndDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                </LocalizationProvider>
            </div>

            
            <div className="row">
                {deviceId && deviceId === "STWFTL0521001" && <STWFTL0521001ReportComponent data={dataLog} /> }
                {deviceId && deviceId === "STWFMBA0122001" && <STWFMBA0122001ReportComponent data={dataLog} /> }
            </div>
          </div>
      </AppLayout>
    )
}
