import { ContactMail } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react'
import CommonLoadingSkeleton from '../../../../components/CommonLoadingSkeleton';
import LabelValue from '../../../../components/InlineEdit/LabelValue'
import withAlert from '../../../../hoc/withAlert'
import deviceServices from '../../deviceServices';
import DeviceContactDetailsForm from './DeviceContactDetailsForm';


const  DeviceContactDetailsComponent = ({deviceId, AppAlert}) =>{

    

    let [deviceContactDetails,setDeviceContactDetails] = useState(null);
    let [loading,setLoadingStatus] = useState(false);


    const getDeviceContactDetails = useCallback(()=>{
        setLoadingStatus(true);
        deviceServices.get(deviceId).then(res=>{
            setDeviceContactDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    },[deviceId]);

    useEffect(()=>{
        getDeviceContactDetails();
    },[getDeviceContactDetails])



    return (
        <>
            {loading && <CommonLoadingSkeleton /> }
            {deviceContactDetails && 
                <div className="card mb-2">
                    <div className="card-header bg-primary text-white">
                        <span className="float-start"> <ContactMail/> <strong> Communication Details </strong></span>
                        <span className="float-end"><DeviceContactDetailsForm refreshList={getDeviceContactDetails} AppAlert={AppAlert} data={deviceContactDetails}/></span>
                    </div>
                    <div className="card-body" id="communication">
                        <div className="row">
                            <div className="col-md-6"> 
                                <LabelValue label={"Mobile1"} value={deviceContactDetails.mobile1 ? deviceContactDetails.mobile1:"N.A" } />
                            </div>
                            <div className="col-md-6"> 
                                <LabelValue label={"Mobile2"} value={deviceContactDetails.mobile2 ? deviceContactDetails.mobile2:"N.A" } />
                            </div>
                            <div className="col-md-6"> 
                                <LabelValue label={"Mobile3"} value={deviceContactDetails.mobile3 ? deviceContactDetails.mobile3:"N.A" } />
                            </div>
                            <div className="col-md-6"> 
                                <LabelValue label={"Email"} value={deviceContactDetails.email ? deviceContactDetails.email:"N.A" } />
                            </div>
                        </div>
                    </div>
                </div>
            }
            
            

        </>
    )
}


export default withAlert(DeviceContactDetailsComponent)